import { get, post } from ".";
import { IShop, IShopProperty, Product, Shop, Show } from "../types";
import {
  failureServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export const ListingGateway = {

  getGenerationDetails: async (generationId: string): Promise<IServiceResponse<any>> => {
    try {
      const res = await get<IServiceResponse<any>>(
        `/admin/generation/${generationId}/details`
      );
      return res;
    } catch (err) {
      return failureServiceResponse("Could not get listing generation details");
    }
  }

};
