const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const numberFormatter = new Intl.NumberFormat("en-US");

export const formatMoney = (value?: number | string | null) => {
  if (value === "" || value === undefined || value === null) {
    return "-";
  }

  if (isNaN(Number(value))) {
    return "-";
  } else {
    return priceFormatter.format(Number(value));
  }
};

export const formatNumber = (num?: number | string | null): string =>
  numberFormatter.format(Number(num ?? 0));
