import { message, Upload, UploadFile } from "antd";
import { RcFile } from "antd/lib/upload";
import type { UploadRequestOption } from "rc-upload/lib/interface";
import MediaUploadUtils from "./MediaUploadUtils";

export const uploadFileProperties = (config: {
  accept?: string;
  onRemove?: (file: UploadFile) => void;
  maxSize?: number;
  maxCount?: number;
  currentCount?: number;
}) => {
  const {
    accept = "image/jpg, image/png",
    maxSize = 20, // unit is MB
    maxCount,
    currentCount,
  } = config;
  return {
    accept,
    beforeUpload: (file: RcFile, fileList: RcFile[]) => {
      if (maxSize && file.size > maxSize * 1000000) {
        message.error(`max size is ${maxSize}MB`);
        return Upload.LIST_IGNORE;
      }

      if (maxCount != null && fileList.length > maxCount) {
        message.error(`file max count is ${maxCount}`);
        return Upload.LIST_IGNORE;
      }
      if (
        maxCount != null &&
        currentCount != null &&
        fileList.length + currentCount > maxCount
      ) {
        message.error(
          `file max count is ${maxCount}, there are already ${currentCount} files`
        );
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    customRequest: async ({
      file,
      onSuccess,
      onError,
    }: UploadRequestOption) => {
      const rcFile = file as RcFile;
      try {
        const downloadUrl = accept.includes("mp4")
          ? await MediaUploadUtils.uploadVideo(rcFile)
          : await MediaUploadUtils.uploadImage(rcFile);
        const uploadedFile = rcFile as UploadFile;
        uploadedFile.url = downloadUrl;
        onSuccess?.(uploadedFile);
        message.success(`${rcFile.name} file uploaded successfully.`);
      } catch (error: any) {
        onError?.(error);
        message.error(`${rcFile.name} file upload failed.`);
      } finally {
      }
    },
  };
};
