import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popover, Radio, Row, Select } from "antd";
import { intersectionBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { MarketplaceGateway } from "../../../api/MarketplaceGateway";
import { useURLSearchParams } from "../../../components/URLSearchParamsProvider";
import { useMobile } from "../../../utils/useMobile";
import { useOnPromise } from "../../../utils/usePromise";

type Tag = {
  id: string;
  name: string;
};

type Props = {};

const Tags = (props: {
  allTags: Tag[];
  loading: boolean;
  onSearch: (value: string) => void;
}) => {
  const { allTags, loading, onSearch } = props;
  const { getUrlParam } = useURLSearchParams();
  const [tags, setTags] = useState<string[]>();
  useEffect(() => {
    const param = getUrlParam("tags") ?? "";
    if (allTags.length > 0 && param.length > 0) {
      setTags(param.split(","));
    }
  }, [allTags, getUrlParam]);

  return (
    <Row>
      <Select
        placeholder="Select tags"
        fieldNames={{ label: "name", value: "id" }}
        options={allTags}
        optionFilterProp="name"
        mode="multiple"
        className="flex-auto"
        value={tags}
        onChange={setTags}
        loading={loading}
        allowClear
      />
      <Button
        icon={
          <SearchOutlined className="text-[#8c8c8c] group-hover:text-[#5595f7]" />
        }
        className="ml-2 group"
        onClick={() => {
          if (tags != null && tags.length > 0) {
            onSearch(tags.join(","));
          } else {
            onSearch("");
          }
        }}
      />
    </Row>
  );
};

export const Search = (props: Props) => {
  const { deleteUrlParam, setUrlParamAndReplaceUrl, getUrlParam, replaceUrl } =
    useURLSearchParams();

  const productId = getUrlParam("productId");
  const keywords = getUrlParam("keywords");
  const tags = getUrlParam("tags");
  const [type, setType] = useState<"keywords" | "productId" | "tags">(
    productId != null ? "productId" : tags != null ? "tags" : "keywords"
  );

  const [allTags, setAllTags] = useState<Tag[]>([]);

  const { onPromise, loading } = useOnPromise(async () => {
    const data = await MarketplaceGateway.getTags();
    setAllTags(data);
  });

  useEffect(() => {
    if (type === "tags") {
      onPromise();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const selectedTagNames = useMemo(() => {
    const tagIds = (tags?.split(",") ?? []).map((id) => ({ id }));
    return intersectionBy(allTags, tagIds, "id")
      .map(({ name }) => name)
      .join(",");
  }, [allTags, tags]);

  const onSearch = (value: string) => {
    deleteUrlParam("keywords");
    deleteUrlParam("productId");
    deleteUrlParam("tags");
    if (value !== "") {
      setUrlParamAndReplaceUrl(type, value, true);
    } else {
      replaceUrl();
    }
  };

  const isMobile = useMobile();

  return (
    <Popover
      showArrow={false}
      content={
        <div className="w-[444px] px-8 py-6">
          <Radio.Group
            value={type}
            onChange={(e) => setType(e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value="keywords">Keywords</Radio.Button>
            <Radio.Button value="productId">Product ID</Radio.Button>
            <Radio.Button value="tags">Existing tags</Radio.Button>
          </Radio.Group>
          <div className="mt-3 w-full">
            {type === "keywords" && (
              <Input.Search
                placeholder="Enter keyword search"
                defaultValue={keywords}
                onSearch={onSearch}
                allowClear
              />
            )}
            {type === "productId" && (
              <Input.Search
                placeholder="Enter product ID"
                defaultValue={productId}
                onSearch={onSearch}
                allowClear
              />
            )}
            {type === "tags" && (
              <Tags allTags={allTags} loading={loading} onSearch={onSearch} />
            )}
          </div>
        </div>
      }
    >
      <Button type="link" icon={<SearchOutlined />}>
        {productId != null
          ? `productId:${productId}`
          : keywords != null
          ? `keywords:${keywords}`
          : tags != null
          ? `tags:${selectedTagNames}`
          : isMobile
          ? ""
          : "Search"}
      </Button>
    </Popover>
  );
};
