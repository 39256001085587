import { ChevronRightIcon } from "@chakra-ui/icons";
import { Avatar, Button, List, Row, Space, Typography } from "antd";
import { useCallback, useState } from "react";
import { MarketplaceGateway } from "../../../api/MarketplaceGateway";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IMarketplaceProduct } from "../../../types";
import { usePromise } from "../../../utils/usePromise";
import { EditProduct } from "../../inventory/components/EditProduct";

type Props = {
  shopId: string;
  unsold?: boolean;
};

const Item = (props: { item: IMarketplaceProduct }) => {
  const { photoURLs = [], name } = props.item;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className="border-b border-neutral-5 my-1 py-4 bg-white hover:bg-[#f5f5f5] cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <Space size={20} align="center">
          <Avatar shape="square" size={82} src={photoURLs[0]} />
          <Typography.Paragraph className="text-h4 !mb-0">
            {name}
          </Typography.Paragraph>
        </Space>
        <Button type="link">
          See details
          <ChevronRightIcon />
        </Button>
      </Row>

      <EditProduct
        open={open}
        onClose={() => setOpen(false)}
        product={props.item}
      />
    </>
  );
};

export const SoldList = (props: Props) => {
  const { shopId, unsold } = props;
  const [page, setPage] = useState(1);
  const [dataSrouce, setDataSrouce] = useState<IMarketplaceProduct[]>([]);
  const { data, reload, loading } = usePromise(async () => {
    const response = await MarketplaceGateway.getProducts({
      shopId,
      isAvailable: !!unsold,
      page,
    });
    const products = response?.data.products ?? [];
    setDataSrouce((pre) => pre.concat(products));
    return response;
  }, [shopId, unsold, page]);

  const reset = useCallback(() => {
    setDataSrouce([]);
    setPage(1);
    reload();
  }, [reload]);

  useReloadContext(reset);

  return (
    <>
      <p className="text-h4">Claims</p>
      <List
        dataSource={dataSrouce}
        renderItem={(item) => <Item item={item} />}
        rowKey="id"
        loading={loading}
      />
      <Row justify="center">
        {!loading && dataSrouce.length < (data?.total ?? 0) && (
          <Button type="link" onClick={() => setPage((pre) => pre + 1)}>
            More
          </Button>
        )}
      </Row>
    </>
  );
};
