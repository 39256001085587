import { TablePaginationConfig } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import React, { ReactNode, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export const DEFAULT_PAGESIZE = 20;

type UrlParamOfInventoryFilter =
  | "isEdited"
  | "editState"
  | "isDeleted"
  | "isAuthenticated"
  | "isDiscounted"
  | "authReviewer"
  | "editorEmail"
  | "sellerName"
  | "keywords"
  | "productId"
  | "shopId"
  | "lastEditor"
  | "priceFrom"
  | "priceTo"
  | "isAvailable";

export type UrlParamOfSingleValue =
  | "cursor"
  | "index"
  | "page"
  | "pageSize"
  | "sort"
  | "search"
  | "sortBy"
  | "oritation"
  | "tags";

export type UrlParam = UrlParamOfSingleValue | UrlParamOfInventoryFilter;

const paramHelper = {
  params: new URLSearchParams(window.location.search),
  getUrlParam<T = string>(name: UrlParam, initialValue?: T) {
    return (paramHelper.params.get(name) ?? initialValue) as T | undefined;
  },
  get commonUrlParams() {
    return {
      cursor: paramHelper.getUrlParam("cursor"),
      index:
        paramHelper.getUrlParam("index") !== undefined
          ? Number(paramHelper.getUrlParam("index"))
          : undefined,
      page: Number(paramHelper.getUrlParam("page") ?? 1),
      pageSize: Number(paramHelper.getUrlParam("pageSize") ?? DEFAULT_PAGESIZE),
      search: paramHelper.getUrlParam("search"),
      sort: paramHelper.getUrlParam("sort"),
    };
  },
  setUrlParam: (name: UrlParam, value: string | string[] | number) =>
    paramHelper.params.set(
      name,
      Array.isArray(value) ? value.join(",") : `${value}`
    ),
  deleteUrlParam: (name: UrlParam) => paramHelper.params.delete(name),
  resetUrlParam: () =>
    paramHelper.params.forEach((_, key) => {
      paramHelper.params.delete(key);
    }),
  replaceUrl: () =>
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${paramHelper.params.toString()}`
    ),
  setUrlParamAndReplaceUrl: (
    name: UrlParam,
    value: string | string[] | number,
    resetPage: boolean = true
  ) => {
    if (resetPage) {
      paramHelper.deleteUrlParam("page");
    }
    paramHelper.setUrlParam(name, value);
    paramHelper.replaceUrl();
  },
  deleteUrlParamAndReplaceUrl: (name: UrlParam, resetPage: boolean = true) => {
    if (resetPage) {
      paramHelper.deleteUrlParam("page");
    }
    paramHelper.deleteUrlParam(name);
    paramHelper.replaceUrl();
  },
  onTableChange: (
    pagination: TablePaginationConfig,
    filters?: Record<string, any[] | null>,
    sorter?: SorterResult<any> | SorterResult<any>[]
  ) => {
    const { pageSize, current } = pagination;

    if (current) {
      paramHelper.setUrlParam("page", current);
    }
    if (pageSize) {
      paramHelper.setUrlParam("pageSize", pageSize);
    }
    if (filters) {
      Object.keys(filters).forEach((key) => {
        const paramKey = key as any;
        const value = filters[paramKey] as string[] | null;
        if (!value || value.length === 0) {
          paramHelper.deleteUrlParam(paramKey);
        } else if (value.length === 1) {
          paramHelper.setUrlParam(paramKey, value[0]);
        } else {
          paramHelper.setUrlParam(paramKey, value.join(","));
        }
      });
    }

    if (sorter) {
      const sorterData = Array.isArray(sorter) ? sorter[0] : sorter;
      const { columnKey, order } = sorterData;
      if (columnKey && order) {
        paramHelper.setUrlParam("sortBy", columnKey);
        paramHelper.setUrlParam(
          "oritation",
          order === "ascend" ? "asc" : "desc"
        );
      } else {
        paramHelper.deleteUrlParam("sortBy");
        paramHelper.deleteUrlParam("oritation");
      }
    }
    paramHelper.replaceUrl();
  },
};
const URLSearchParamsFactoryContext = React.createContext<typeof paramHelper>(
  null as any
);

export const useURLSearchParams = (initialValue?: { pageSize?: number }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = useContext(URLSearchParamsFactoryContext);
  data.params = new URLSearchParams(location.search);
  data.replaceUrl = () =>
    navigate(`${window.location.pathname}?${data.params.toString()}`, {
      replace: true,
    });

  if (initialValue) {
    if (initialValue.pageSize != null && data.getUrlParam("pageSize") == null) {
      data.setUrlParam("pageSize", initialValue.pageSize);
    }
  }
  return data;
};

export const URLSearchParamsProvider = (props: { children: ReactNode }) => {
  return (
    <URLSearchParamsFactoryContext.Provider value={paramHelper}>
      {props.children}
    </URLSearchParamsFactoryContext.Provider>
  );
};
