import {
  EditFilled,
  EyeFilled,
  EyeInvisibleFilled,
  MenuOutlined,
} from "@ant-design/icons";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Avatar, Button, Row, Space, Spin, Tabs } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CollectionGateway } from "../../../api/CollectionGateway";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IProductCollection } from "../../../types/IProductCollection";
import { getBgAndTextColor } from "../../../utils/color";
import { formatDate } from "../../../utils/time";
import { useOnPromise, usePromise } from "../../../utils/usePromise";
import { EditCollection } from "./AddCollection";

type Props = {};

const HideButton = (props: { collection: IProductCollection }) => {
  const { reload } = useReloadContext() ?? {};
  const { onPromise, loading } = useOnPromise(async (e?: any) => {
    try {
      e.stopPropagation();
      await CollectionGateway.hideCollection(props.collection);
      reload?.();
    } catch (error) {}
  });

  return (
    <Button type="link" loading={loading} onClick={onPromise}>
      Hide from collection
      <EyeInvisibleFilled />
    </Button>
  );
};

const UnhideButton = (props: { collection: IProductCollection }) => {
  const { reload } = useReloadContext() ?? {};
  const { onPromise, loading } = useOnPromise(async (e?: any) => {
    try {
      e.stopPropagation();
      await CollectionGateway.unhideCollection(props.collection);
      reload?.();
    } catch (error) {}
  });

  return (
    <Button type="link" loading={loading} onClick={onPromise}>
      Unhide from collection
      <EyeFilled />
    </Button>
  );
};

const Collection = (props: {
  item: IProductCollection;
  hidden?: boolean;
  onEdit: () => void;
}) => {
  const { item, hidden } = props;

  const { attributes, listeners } = useSortable({ id: item.id });
  return (
    <Row
      justify="space-between"
      align="middle"
      className="border border-grey-10 rounded-lg my-1 p-5 bg-white hover:bg-[#f5f5f5] cursor-pointer"
      onClick={props.onEdit}
    >
      <Space>
        {hidden ? (
          <div className="w-4" />
        ) : (
          <MenuOutlined {...attributes} {...listeners} />
        )}
        {item.photoURLs[0] ? (
          <Avatar
            shape="square"
            size={120}
            src={item.photoURLs[0]}
            className="ml-4"
          />
        ) : (
          <Avatar
            shape="square"
            size={120}
            src={item.photoURLs[0]}
            className="ml-4"
            style={{
              backgroundColor: getBgAndTextColor(item.name)[0],
              color: getBgAndTextColor(item.name)[1],
            }}
          >
            {item.name}
          </Avatar>
        )}
        <Space direction="vertical">
          <p className="text-h3">{item.name}</p>
          {!hidden && <p className="text-body">Rank: {item.rank}</p>}
          <p className="text-body text-black text-opacity-70">
            {item.items.length} items • Added on{" "}
            {formatDate(new Date(item.createdAt))}
          </p>
        </Space>
      </Space>
      <Space direction="vertical" align="end">
        <Button type="link">
          Edit collection
          <EditFilled />
        </Button>
        {hidden ? (
          <UnhideButton collection={item} />
        ) : (
          <HideButton collection={item} />
        )}
      </Space>
    </Row>
  );
};

const SortableItem = (props: {
  collection: IProductCollection;
  onEdit: () => void;
}) => {
  const { collection, onEdit } = props;
  const { setNodeRef, transform, transition } = useSortable({
    id: collection.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Collection item={collection} onEdit={onEdit} />
    </div>
  );
};

const HiddenCollections = (props: {
  collections: IProductCollection[];
  onEdit: (item: IProductCollection) => void;
}) => {
  const { collections, onEdit } = props;
  return (
    <>
      {collections.map((collection) => (
        <Collection
          key={collection.id}
          item={collection}
          hidden
          onEdit={() => onEdit(collection)}
        />
      ))}
    </>
  );
};

export const CollectionTable = (props: Props) => {
  const [open, setOpen] = useState<IProductCollection>();

  const { data, loading, reload } = usePromise(() =>
    CollectionGateway.getCollections()
  );

  const { onPromise, loading: updating } = useOnPromise(
    async (collections: IProductCollection[]) => {
      await Promise.all(
        collections.map((collection, index) => {
          const { items, ...rest } = collection;
          return CollectionGateway.updateCollection(
            {
              ...rest,
              rank: index + 1,
            },
            items.map((item) => item.id),
            items[0]?.photoURLs ?? []
          );
        })
      );
      await reload();
    }
  );

  useReloadContext(reload);

  const [collections, setCollections] = useState(data ?? []);
  const [hiddenCollections, setHiddenCollections] = useState(data ?? []);
  useEffect(() => {
    setCollections(
      (data ?? [])
        .filter((item) => item.rank !== 0)
        .sort((a, b) => a.rank - b.rank)
    );
    setHiddenCollections((data ?? []).filter((item) => item.rank === 0));
  }, [data]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    })
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = collections.findIndex((item) => item.id === active.id);
        const newIndex = collections.findIndex((item) => item.id === over?.id);

        const items = arrayMove(collections, oldIndex, newIndex);
        setCollections(items);
        onPromise(items);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collections]
  );

  const items = useMemo(
    () => [
      {
        key: "active",
        label: "Active collections",
        children: (
          <Spin spinning={loading || updating}>
            <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
              <SortableContext
                items={collections.map((item) => item.id)}
                strategy={verticalListSortingStrategy}
              >
                {collections.map((collection) => (
                  <SortableItem
                    key={collection.id}
                    collection={collection}
                    onEdit={() => setOpen(collection)}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </Spin>
        ),
      },
      {
        key: "hidden",
        label: "Hidden collections",
        children: (
          <Spin spinning={loading}>
            <HiddenCollections
              collections={hiddenCollections}
              onEdit={setOpen}
            />
          </Spin>
        ),
      },
    ],
    [collections, handleDragEnd, hiddenCollections, loading, sensors, updating]
  );

  return (
    <>
      <Tabs items={items} />
      <EditCollection
        open={!!open}
        onClose={() => setOpen(undefined)}
        title="Edit collection"
        collection={open}
      />
    </>
  );
};
