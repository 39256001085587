import { PlusOutlined } from "@ant-design/icons";
import { Button, FloatButton, FloatButtonProps } from "antd";
import { useMobile } from "../../utils/useMobile";

export const AutoFloatButton = (props: FloatButtonProps) => {
  const isMobile = useMobile();
  const { description, icon = <PlusOutlined />, ...rest } = props;
  return isMobile ? (
    <FloatButton icon={icon} {...rest} />
  ) : (
    <Button type="primary" onClick={props.onClick}>
      {description}
    </Button>
  );
};
