import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { FloatButtonType } from "antd/es/float-button/interface";
import _, { pickBy, unionBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { MarketplaceGateway } from "../../../api/MarketplaceGateway";
import { ICreateMarketplaceProductInput } from "../../../api/MarketplaceGateway.type";
import { AutoFloatButton } from "../../../components/AutoFloatButton";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IMarketplaceProduct } from "../../../types";
import { useMobile } from "../../../utils/useMobile";
import { useOnPromise, usePromise } from "../../../utils/usePromise";
import { Times } from "../../giveaways/components/AddGiveaway";
import { PhotosInput } from "./formItems/PhotosInput";
import { SellerInput } from "./formItems/SellerInput";
import { SizeInput } from "./formItems/SizeInput";

type Seller = { id: string; name: string };

type Props = {
  open: boolean;
  onClose: () => void;
  seller?: Seller;
};

export const Title = (props?: { title?: string; placeholder?: string }) => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">{props?.title ?? "Title"}</p>
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please input product title" }]}
      >
        <Input placeholder={props?.placeholder} />
      </Form.Item>
    </>
  );
};
export const Description = (props?: {
  title?: string;
  placeholder?: string;
}) => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Description</p>
      <Form.Item name="description">
        <Input.TextArea placeholder={props?.placeholder} />
      </Form.Item>
    </>
  );
};

export const Tags = (props: { defaultTags?: IMarketplaceProduct["tags"] }) => {
  const { defaultTags } = props;
  const { data } = usePromise(() => MarketplaceGateway.getTags(), []);

  const options = useMemo(() => {
    return unionBy(defaultTags ?? [], data, "name");
  }, [data, defaultTags]);

  return (
    <>
      <p className="text-h3 mt-6 mb-3">Tags</p>
      <Form.Item name="tagIds">
        <Select
          options={options}
          mode="multiple"
          fieldNames={{ label: "name", value: "id" }}
          optionFilterProp="name"
        />
      </Form.Item>
    </>
  );
};

export const Authentication = () => {
  // const mail = useMemo(() => getAuth().currentUser?.email, []);

  return (
    <>
      <p className="text-h3 mt-6">Authentication details</p>
      <Row justify="space-between" className="mt-3">
        <div>
          <p className="text-h4">Status</p>
          <Form.Item name="authStatus" noStyle>
            <Select
              className="mt-4 w-[295px] text-body"
              placeholder="Select status"
            >
              <Select.Option value="AUTHENTICATED">Authenticated</Select.Option>
              <Select.Option value="CANT_AUTHENTICATE">
                Cannot determine authenticity
              </Select.Option>
              <Select.Option value="NOT_AUTHENTICATED">
                Not reviewed
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
      </Row>
    </>
  );
};

export const Measurement = () => {
  return (
    <div>
      <p className="text-h3">Measurement</p>
      <Space className="mt-4" wrap>
        <Form.Item name="width">
          <InputNumber min={0} placeholder="Pit to pit" className="w-40" />
        </Form.Item>
        <Form.Item name="height">
          <InputNumber min={0} placeholder="Top to bottom" className="w-40" />
        </Form.Item>
      </Space>
    </div>
  );
};

export const Price = (props: { name?: string; title?: string }) => {
  const { name = "price", title = "Price" } = props;
  return (
    <div>
      <p className="text-h4 mb-4">{title}</p>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: "Please input price",
          },
        ]}
      >
        <InputNumber
          min={0}
          precision={2}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          addonBefore="$"
        />
      </Form.Item>
    </div>
  );
};

export const Discount = () => {
  return (
    <div>
      <p className="text-h4 mb-4">Discount</p>
      <Form.Item name="discountAmount">
        <InputNumber max={100} min={0} addonAfter="%" />
      </Form.Item>
    </div>
  );
};

export const PriceSection = (props: { auction?: boolean }) => {
  const [auction, setAuction] = useState(!!props.auction);

  return (
    <div>
      <Radio.Group
        onChange={() => setAuction(!auction)}
        value={auction}
        className="text-h4"
      >
        <Radio value={true}>Sell as auction item</Radio>
        <Radio value={false}>Sell as a claim item</Radio>
      </Radio.Group>
      <div className="mt-4">
        {auction ? (
          <>
            <Times
              endTitle="End time"
              startName="auctionStartsAt"
              endName="auctionEndsAt"
              titleClassName="text-h4 mb-3"
            />
            <Price title="Auction Start Price" name="auctionStartPrice" />
            <Price title="Claim Price" name="price" />
          </>
        ) : (
          <Space>
            <Price />
            <Discount />
          </Space>
        )}
      </div>
    </div>
  );
};

const AddProduct = (props: Props) => {
  const { open, onClose, seller } = props;

  const isMobile = useMobile();

  const { reload } = useReloadContext() ?? {};
  const { onPromise, loading } = useOnPromise(async (clear: boolean) => {
    const { photoURLs, source, size, auctionStartsAt, auctionEndsAt, ...data } =
      await form.validateFields();

    await MarketplaceGateway.createProduct({
      ...data,
      photoURLs: photoURLs.map((item) => item.url),
      metadata: source != null ? { url: source } : undefined,
      price: Number(data.price ?? 9999999999),
      size: String(size),
      shopId: data.shopId === seller?.name ? seller?.id : data.shopId,
      auctionStartPrice: !_.isNil(data.auctionStartPrice)
        ? Number(data.auctionStartPrice)
        : undefined,
      auctionStartsAt: auctionStartsAt?.toDate().toISOString(),
      auctionEndsAt: auctionEndsAt?.toDate().toISOString(),
    });
    reload?.();
    if (clear) {
      form.resetFields();
    } else {
      onClose();
    }
  });

  const [form] = Form.useForm<ICreateMarketplaceProductInput>();
  useEffect(() => {
    if (open) {
      form.resetFields();
      if (seller) {
        form.setFieldsValue({
          type: "FIRST_PARTY",
          shopId: seller.name,
        });
      }
    }
  }, [form, open, seller]);

  return (
    <Modal
      open={open}
      width={isMobile ? "100%" : "60%"}
      onCancel={(e) => {
        if (
          (e.target as any).className === "ant-modal-wrap" ||
          (e as any).key === "Escape"
        ) {
          const { shopId, type, authStatus, ...currentValue } =
            form.getFieldsValue();
          if (Object.keys(pickBy(currentValue)).length > 0) {
            Modal.confirm({
              title: "Are you sure you want to close without saving changes",
              onOk: onClose,
            });
            return;
          }
        }
        onClose();
      }}
      title="Add a product"
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => onPromise(false)}
        >
          Save product
        </Button>,
        <Button
          key="submitAndNew"
          loading={loading}
          onClick={() => onPromise(true)}
        >
          save and add new product
        </Button>,
        <Button key="back" type="link" onClick={onClose}>
          Cancel changes
        </Button>,
      ]}
      destroyOnClose
    >
      <Form
        form={form}
        onValuesChange={(value) => {
          const { shopId, type } = value;
          if (shopId != null) {
            form.setFieldValue("type", "FIRST_PARTY");
          } else if (type != null) {
            form.resetFields(["source", "shopId"]);
          }
        }}
        initialValues={{ authStatus: "NOT_AUTHENTICATED" }}
        onClick={(e) => e.stopPropagation()}
      >
        <Title />
        <Description />
        <SellerInput />
        <PhotosInput maxCount={10} />
        <Tags />
        <Authentication />
        <Row justify="space-between" className="mt-6" wrap>
          <SizeInput />
          <Measurement />
        </Row>
        <PriceSection />
      </Form>
    </Modal>
  );
};

export const AddProductButton = (props?: {
  seller?: Seller;
  type?: FloatButtonType;
}) => {
  const { seller, type = "primary" } = props ?? {};
  const [open, setOpen] = useState(false);
  return (
    <>
      <AutoFloatButton
        type={type}
        onClick={() => setOpen(true)}
        description="Add new product"
      />
      <AddProduct open={open} seller={seller} onClose={() => setOpen(false)} />
    </>
  );
};
