import { get, post, put } from ".";
import { IProductDrop } from "../types/IProductDrop";
import { IServiceResponse } from "../types/IServiceResponse";

export const DropGateway = {
  getDrops: async (shopId: string): Promise<IProductDrop[]> => {
    try {
      const res = await get<IServiceResponse<IProductDrop[]>>("/collections", {
        type: "DROP",
        shopId,
      });
      return res.payload ?? [];
    } catch (err) {
      throw new Error("Could not get drops");
    }
  },
  createDrop: async (
    drop: Omit<
      IProductDrop,
      "id" | "items" | "rank" | "createdAt" | "updatedAt" | "type"
    >,
    productIds: string[]
  ) => {
    try {
      const res = await post<IServiceResponse<IProductDrop>>("/collections", {
        ...drop,
        type: "DROP",
        productIds,
        rank: 1,
      });
      return res.payload?.id;
    } catch (error) {
      throw new Error("Could not create drop");
    }
  },
  updateDrop: async (
    drop: Omit<
      IProductDrop,
      "items" | "rank" | "createdAt" | "updatedAt" | "type"
    >,
    productIds: string[]
  ) => {
    try {
      await put<IServiceResponse<any>>(`/collections/${drop.id}`, {
        ...drop,
        productIds,
        rank: 1,
      });
    } catch (err) {
      throw new Error("Could not update collection");
    }
  },
  endDrop: async (drop: IProductDrop) => {
    try {
      const { items, createdAt, updatedAt, ...rest } = drop;
      await put<IServiceResponse<any>>(`/collections/${drop.id}`, {
        ...rest,
        endsAt: new Date().toISOString(),
      });
    } catch (err) {
      throw new Error("Could not end collection");
    }
  },
};
