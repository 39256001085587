import { Spin } from "antd";

type Props = {
  className?: string;
};

export const Loading = (props: Props) => {
  return (
    <div
      className={`w-full h-screen flex justify-center items-center ${
        props.className ?? ""
      }`}
    >
      <Spin />
    </div>
  );
};
