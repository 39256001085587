export const EnvironmentName: "Local" | "DEVO" | "PROD" = "PROD";
export const EnvironmentURL = "https://sgapi.shopsogood.live";
export const FirebaseConfig = {
  apiKey: "AIzaSyAoPu8CPLcsIiBeMhjahZcmuxEXytxohU0",
  authDomain: "sogood-live-backend.firebaseapp.com",
  databaseURL: "https://sogood-live-backend-default-rtdb.firebaseio.com",
  projectId: "sogood-live-backend",
  storageBucket: "sogood-live-backend.appspot.com",
  messagingSenderId: "992023631103",
  appId: "1:992023631103:web:9dbdd1c886094c038fa8ca",
  measurementId: "G-30YQ6Q6GCG",
};
