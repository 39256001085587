import { get, post, put } from ".";
import { IProductCollection } from "../types/IProductCollection";
import { IServiceResponse } from "../types/IServiceResponse";

export const CollectionGateway = {
  getCollections: async (): Promise<IProductCollection[]> => {
    try {
      const res = await get<IServiceResponse<IProductCollection[]>>(
        "/collections"
      );
      return res.payload ?? [];
    } catch (err) {
      throw new Error("Could not get collections");
    }
  },
  createCollection: async (
    collection: Omit<IProductCollection, "items" | "id">,
    productIds: string[],
    photoURLs: string[]
  ) => {
    try {
      const res = await post<IServiceResponse<IProductCollection>>(
        "/collections",
        {
          ...collection,
          rank: 1,
          productIds,
          photoURLs,
          type: "NORMAL",
        }
      );
      return res.payload?.id;
    } catch (error) {
      throw new Error("Could not update collection");
    }
  },
  updateCollection: async (
    collection: Omit<IProductCollection, "items">,
    productIds: string[],
    photoURLs: string[]
  ) => {
    try {
      await put<IServiceResponse<any>>(`/collections/${collection.id}`, {
        ...collection,
        photoURLs,
        productIds,
      });
    } catch (err) {
      throw new Error("Could not update collection");
    }
  },
  hideCollection: async (collection: IProductCollection) => {
    const { items, ...rest } = collection;
    try {
      await put<IServiceResponse<any>>(`/collections/${collection.id}`, {
        ...rest,
        productIds: collection.items.map((item) => item.id),
        rank: 0,
      });
    } catch (err) {
      throw new Error("Could not hide collection");
    }
  },
  unhideCollection: async (collection: IProductCollection) => {
    const { items, ...rest } = collection;
    try {
      await put<IServiceResponse<any>>(`/collections/${collection.id}`, {
        ...rest,
        productIds: collection.items.map((item) => item.id),
        rank: 99,
      });
    } catch (err) {
      throw new Error("Could not unhide collection");
    }
  },
};
