const hsl2Rgb = (h: number, s: number, l: number) => {
  let r, g, b;
  const hue2rgb = (p: number, q: number, ot: number) => {
    let t = ot;
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };
  if (s === 0) {
    r = l;
    g = l;
    b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return [r, g, b].map((c) => Math.round(c * 255));
};

const contrastTextColor = ([r, g, b]: number[]) => {
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 147 ? "black" : "white";
};

export const getBgAndTextColor = (str: string) => {
  const h =
    str.split("").reduce((acc, cur, idx) => acc * 3 + cur.charCodeAt(0), 0) %
    360;
  const color = hsl2Rgb(h / 360, 1, 0.66);
  const textColor = contrastTextColor(color);
  return [`rgb(${color.join(",")})`, textColor];
};
