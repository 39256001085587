import { post } from ".";
import {
  failureServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export const NotificationsGateway = {
  getRouteApp(target: string): string {
    if (target === "A") {
      return "/amphorae";
    }
    return "";
  },

  sendNotificationToDevice: async (
    title: string,
    message: string,
    deviceId: string,
    target: string
  ): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>(
        `/admin${NotificationsGateway.getRouteApp(
          target
        )}/installations/${deviceId}/notification`,
        {
          title: title,
          message: message,
        }
      );
    } catch (err) {
      return failureServiceResponse("could not send notification");
    }
  },
  sendNotificationToUser: async (
    title: string,
    message: string,
    userId: string,
    target: string
  ): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>(
        `/admin${NotificationsGateway.getRouteApp(
          target
        )}/users/${userId}/notification`,
        {
          title: title,
          message: message,
        }
      );
    } catch (err) {
      return failureServiceResponse("could not send notification");
    }
  },
  sendNotificationToAll: async (
    title: string,
    message: string,
    target: string,
    targetAudience: string
  ): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>(
        `/admin${NotificationsGateway.getRouteApp(target)}/notifications/all`,
        {
          title: title,
          message: message,
          targetAudience: targetAudience,
        }
      );
    } catch (err) {
      return failureServiceResponse("could not send notification");
    }
  },

  scheduleNotif: async (date: Date): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>(
        `/admin/amphorae/notifications/schedule`,
        { date }
      );
    } catch (err) {
      return failureServiceResponse("could not send notification");
    }
  },
};
