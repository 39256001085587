import {
  CheckCircleOutlined,
  EyeOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Carousel,
  Checkbox,
  message,
  Popconfirm,
  Row,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import { MarketplaceGateway } from "../../../api/MarketplaceGateway";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import {
  IMarketplaceProduct,
  IMarketplaceProductAuction,
} from "../../../types";
import { formatMoney } from "../../../utils/number";
import { formatDate } from "../../../utils/time";
import { EditProduct } from "./EditProduct";

type Props = {
  item: IMarketplaceProduct;
  checkbox?: {
    checked: boolean;
    onChange: (checked: boolean, item: IMarketplaceProduct) => void;
  };
};

const Authenticated = () => {
  return (
    <Row align="middle" className="gap-1">
      <CheckCircleOutlined className="text-green" />
      <Typography.Text className="text-green text-subtitle">
        Authenticated
      </Typography.Text>
    </Row>
  );
};

const CannotAuthenticity = () => {
  return (
    <Row align="middle" className="gap-1">
      <WarningOutlined className="text-red" />
      <Typography.Text className="text-red text-subtitle">
        Cannot determine authenticity
      </Typography.Text>
    </Row>
  );
};

const NeedReview = () => {
  return (
    <Row align="middle" className="gap-1">
      <EyeOutlined className="text-yellow" />
      <Typography.Text className="text-yellow">Needs review</Typography.Text>
    </Row>
  );
};

const Cover = (props: { photoURLs: string[] }) => {
  return (
    <div className="w-full cursor-auto">
      <Carousel lazyLoad="ondemand">
        {props.photoURLs.map((url) => (
          <Row
            key={url}
            justify="center"
            className="h-[360px] md:h-[136px] w-full overflow-hidden relative"
          >
            <div
              style={{
                background: `url("${url}")`,
                backgroundSize: "100% 100%",
              }}
              className={`h-full w-full  blur-2xl`}
            />
            <img
              alt=""
              src={url}
              className="h-[360px] md:h-[136px] w-full object-contain absolute top-0 "
            />
          </Row>
        ))}
      </Carousel>
    </div>
  );
};

const AuctionPrice = (props: { auction: IMarketplaceProductAuction }) => {
  const { auction } = props;
  const auctionStartPrice =
    auction.startPrice != null ? Number(auction.startPrice) : undefined;
  const auctionStartsAt =
    auction.startsAt != null
      ? formatDate(new Date(auction.startsAt), "MM-DD hh:mm")
      : "/";
  const auctionEndsAt =
    auction.endsAt != null
      ? formatDate(new Date(auction.endsAt), "MM-DD hh:mm")
      : "/";

  return (
    <Space direction="vertical" size={0}>
      <Typography.Text className="text-h4">
        Bid start: {formatMoney(auctionStartPrice)}
      </Typography.Text>
      <Typography.Text>
        ({auctionStartsAt}
        {" -> "}
        {auctionEndsAt})
      </Typography.Text>
    </Space>
  );
};

export const ProductCard = (props: Props) => {
  const { id, name, updatedAt, photoURLs, authStatus, isEdited, deleted } =
    props.item;
  const price = Number(props.item.price);
  const discountedPrice =
    props.item.discountedPrice != null
      ? Number(props.item.discountedPrice)
      : undefined;
  const auction = props.item.auction;

  const [open, setOpen] = useState(false);
  const { reload } = useReloadContext() ?? {};

  return (
    <>
      <Card
        hoverable
        className="min-w-[268px] h-[520px] md:h-[310px] rounded-md md:mr-5 mb-5 overflow-hidden"
        cover={<Cover photoURLs={photoURLs} />}
      >
        <Space
          direction="vertical"
          className="w-full"
          size={4}
          onClick={() => setOpen(true)}
        >
          <div className="h-5">
            {authStatus === "AUTHENTICATED" ? (
              <Authenticated />
            ) : authStatus === "CANT_AUTHENTICATE" ? (
              <CannotAuthenticity />
            ) : (
              <NeedReview />
            )}
          </div>
          <Typography.Paragraph
            className="text-h4"
            ellipsis={{ rows: 2 }}
            style={{ marginBottom: 0 }}
          >
            {name}
          </Typography.Paragraph>
          {!!auction ? (
            <AuctionPrice auction={auction} />
          ) : (
            <Space>
              <Typography.Text
                className="text-h4"
                delete={discountedPrice != null && price !== discountedPrice}
              >
                {formatMoney(price)}
              </Typography.Text>
              {discountedPrice != null && (
                <Typography.Text className="text-h4">
                  {formatMoney(discountedPrice)}
                </Typography.Text>
              )}
            </Space>
          )}
          <Typography.Text className="text-black text-subtitle">
            Refreshed {formatDate(new Date(updatedAt))}
            {isEdited ? (
              <>
                {" "}
                •
                <Typography.Text className=" text-blue font-bold">
                  Edited
                </Typography.Text>
              </>
            ) : (
              ""
            )}
          </Typography.Text>
        </Space>
        {props.checkbox ? (
          <Checkbox
            onClick={(e) => {
              props.checkbox!.onChange(!props.checkbox!.checked, props.item);
            }}
            checked={props.checkbox.checked}
            className="absolute left-4 top-4"
          />
        ) : (
          !deleted && (
            <Popconfirm
              title="Are you sure to delete this item?"
              icon={null}
              okText="Delete"
              okButtonProps={{ danger: true, size: "middle" }}
              cancelButtonProps={{ size: "middle" }}
              onConfirm={async (e) => {
                try {
                  await MarketplaceGateway.deleteProduct(id);
                  reload?.();
                } catch (e) {
                  message.error("Failed");
                }
              }}
            >
              <Button
                className="absolute right-4 bottom-4"
                type="primary"
                size="middle"
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          )
        )}
      </Card>
      <EditProduct
        open={open}
        onClose={() => setOpen(false)}
        product={props.item}
      />
    </>
  );
};
