import { Breadcrumb, Carousel, Row, Tabs, Typography } from "antd";
import { useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ShopGateway } from "../../api/ShopGateway";
import { Loading } from "../../components/Loading";
import { usePromise } from "../../utils/usePromise";
import { AddProductButton } from "../inventory/components/AddProduct";
import { ListingGateway } from "../../api/ListingGateway";
import _ from "lodash";

type Props = {};

export const ListingGeneration = (props: Props) => {
  const params = useParams<{ generationId: string }>();
  const { state } = useLocation();

  const excludedFields = ["front_photo_url", "tag_photo_urls"]

  const { data, loading } = usePromise(async () => {
    if (state) {
      return state.name;
    } else {
      const data = await ListingGateway.getGenerationDetails(params.generationId!);
      return data.payload
    }
  }, [state]);

  if (loading) {
    return <Loading />;
  }

	if (_.isNil(data)) {
		return <Loading />
	}

  return (
    <div className="page-container">
      <div style={{overflowY: "scroll"}}>
        <Typography.Paragraph
          className="text-h2"
          ellipsis={{ rows: 2 }}
          style={{ marginBottom: 0 }}
        >
          {`Listing Generation - ${params.generationId}`}
        </Typography.Paragraph>
        <div className="flex" style={{ overflow: "scroll" }}>
          <img
            alt=""
            src={data.front_photo_url}
            className="h-[500px] md:h-[300px] w-full object-contain mr-5"
          />
          {_.map(data.tag_photo_urls, (photoUrl: string) => {
            return (
              <img
                alt=""
                src={photoUrl}
                className="h-[500px] md:h-[300px] w-full object-contain mr-5"
              />
            );
          })}
        </div>

        <div>
          {Object.keys(data).map((key: string) => {
            if (excludedFields.includes(key)) {
              return null
            }
            return (
              <div>
                <Typography.Text className="text-h3">{key}</Typography.Text>
                <Typography.Paragraph className="text-body">
                  {JSON.stringify(data[key])}
                </Typography.Paragraph>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
