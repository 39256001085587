import _ from "lodash";
import { 
  Box, 
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Text,
  theme,
  VStack
} from "@chakra-ui/react";
import { Dots } from "react-activity";
import { useEffect, useState } from "react";
import { DiscountGateway } from "../../api/DiscountGateway";
import { Discount } from "../../types";
import { AddDiscountForm } from "../AddDiscountForm";
import { DiscountForm } from "../DiscountForm";
import { DiscountDetail } from "../DiscountDetail";

export const Discounts = ({
  handleClose,
}: { 
  handleClose: () => void;
}) => {
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loadingDiscounts, setLoadingDiscounts] = useState(false);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [selectedDiscount, setSelectedDiscount] = useState<Discount | undefined>(undefined);
  const [isCreatingDiscount, setIsCreatingDiscount] = useState(false);

  useEffect(() => {
    refreshDiscountData();
  }, []);

  const refreshDiscountData = async() => {
    setLoadingDiscounts(true);
    DiscountGateway.getDiscounts()
    .then((discounts) => {
      setDiscounts(discounts);
      setLoadingDiscounts(false);
    });
  };

  useEffect(() => {
    if (refresh) {
      setIsCreatingDiscount(false);
      refreshDiscountData();
      setRefresh(false);
    }
  }, [refresh]);

  const onCreateDiscount = async () => {
    setSelectedDiscount(undefined);
    setIsCreatingDiscount(true);
  };

  const handleDiscountClick = async (discount: Discount) => {
    setIsCreatingDiscount(false);
    setSelectedDiscount(discount);
  };

  return (
    <Grid
      h="90vh"
      w={"100%"}
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(3, 1fr)"
      gap={3}
      overflow={"scroll"}
      >
        <GridItem
          rowSpan={2}
          colSpan={1}
          bg={theme.colors.gray[100]}
          padding={"10px"}
          overflow={"scroll"}
        >
          <VStack overflowY={"hidden"}>
            <Button
              w="100%"
              colorScheme={"blue"}
              onClick={onCreateDiscount}
              >
              Create
            </Button>
            
            {loadingDiscounts ? (
              <Center w="100%" h="500">
                <Dots size={50} color={theme.colors.blue[200]} />
              </Center>
            ) : (
              discounts.map((discount) => (
                <DiscountDetail
                  key={discount.id}
                  discount={discount}
                  handleClick={handleDiscountClick}
                  selectedDiscount={selectedDiscount}
                />
              ))
            )}

          </VStack>
        </GridItem>

        <GridItem colSpan={2} bg="papayawhip" padding={"10px"}>
          <VStack>
            {
              selectedDiscount &&
                <DiscountForm
                  refresh={refresh}
                  discount={selectedDiscount}
                  setRefresh={setRefresh}
                  setSelectedDiscount={setSelectedDiscount}
                />
            }
            {
              isCreatingDiscount &&
                <AddDiscountForm
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
            }
          </VStack>
        </GridItem>
    </Grid>
  );
};
