import { EditFilled } from "@ant-design/icons";
import { Avatar, Button, List, message, Popconfirm, Row, Space } from "antd";
import { useState } from "react";
import { RaffleGateway } from "../../../api/RaffleGateway";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IRaffle, RaffleStatus } from "../../../types/IRaffle";
import { formatMoney } from "../../../utils/number";
import { formatDate } from "../../../utils/time";
import { useMobile } from "../../../utils/useMobile";
import { usePromise } from "../../../utils/usePromise";
import { EditGiveaway } from "./AddGiveaway";
import { WinnerInfo } from "./WinnerInfo";

type Props = {
  status: RaffleStatus;
};

const Giveaway = (props: { data: IRaffle; status: RaffleStatus }) => {
  const { data, status } = props;

  const isMobile = useMobile();
  const [open, setOpen] = useState(false);

  const isEnd = status === "ended";
  const { reload } = useReloadContext() ?? {};

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className={`border border-grey-10 rounded-lg my-1 py-4 pl-8 pr-4 bg-white hover:bg-[#f5f5f5] ${
          isEnd ? "" : "cursor-pointer"
        }`}
        onClick={() => !isEnd && setOpen(true)}
      >
        <Space size={16} className="overflow-hidden">
          <Avatar
            shape="square"
            size={isMobile ? 120 : 160}
            src={data.backgroundURL}
          />
          <Space direction="vertical" className="w-full">
            <p className="text-h3 w-full">{data.title}</p>
            <p className="text-h4 text-black text-opacity-70">
              Ending {formatDate(new Date(data.entryCutoff), "MMM D, YYYY")} at{" "}
              {formatDate(new Date(data.entryCutoff), "h:mma Z")}
            </p>
            <p className="text-body">
              {data.enteredUserCount} users entered•{data.entriesCount} entries
            </p>
            {data.sponsor?.name && (
              <p className="text-body">
                Sponsored by{" "}
                <span className="text-link">@{data.sponsor.name}</span>
              </p>
            )}
            {data.estimatedValue != null && (
              <p className="text-body opacity-50">
                {formatMoney(data.estimatedValue)} estimated value
              </p>
            )}
          </Space>
        </Space>
        {isEnd ? (
          data.winnerId && <WinnerInfo id={data.winnerId} user={data.winner} />
        ) : (
          <Space direction="vertical">
            <Button type="link">
              Edit giveaway
              <EditFilled />
            </Button>
            {status === "active" && (
              <Popconfirm
                title="Are you sure you want to draw the winner?"
                icon={null}
                onConfirm={async (e) => {
                  e?.stopPropagation();
                  const response = await RaffleGateway.runDrawing(data.id);

                  if (response.success) {
                    message.success("Success");
                    reload?.();
                  } else {
                    message.error(response.message);
                  }
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
              >
                <Button type="primary" onClick={(e) => e.stopPropagation()}>
                  Run drawing
                </Button>
              </Popconfirm>
            )}
          </Space>
        )}
      </Row>
      {!isEnd && (
        <EditGiveaway
          open={open}
          collection={data}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export const GiveawayList = (props: Props) => {
  const { status } = props;

  const { data, loading, reload } = usePromise(() =>
    RaffleGateway.getRaffles(status)
  );
  useReloadContext(reload);

  return (
    <List
      loading={loading}
      dataSource={data?.payload ?? []}
      renderItem={(item) => <Giveaway data={item} status={status} />}
      rowKey="id"
    />
  );
};
