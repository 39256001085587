import { Button, Center, ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ConfigProvider, Watermark } from "antd";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { get } from "./api";
import App from "./App";
import { Loading } from "./components/Loading";
import { ReloadContextProvider } from "./components/Providers/ReloadProvider";
import { URLSearchParamsProvider } from "./components/URLSearchParamsProvider";
import { EnvironmentName, FirebaseConfig } from "./config";
import "./index.css";
import { Collections } from "./pages/collections";
import { CreditsManager } from "./pages/credits";
import { Giveaways } from "./pages/giveaways";
import { Inventory } from "./pages/inventory";
import { ISOInventory } from "./pages/iso";
import { SellerManager } from "./pages/sellers";
import { ListingManager } from "./pages/listing";

const Login = () => {
  const onSignInWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    // auth.setPersistence(browserSessionPersistence);
    signInWithPopup(auth, provider).then(() => {
      console.log("SIGNED IN");
    });
  };

  return (
    <Center w="100%" h="100vh">
      <Button onClick={onSignInWithGoogle}>Sign In With SoGood Email</Button>
    </Center>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/inventory",
    element: <Inventory />,
  },
  {
    path: "/collections",
    element: <Collections />,
  },
  {
    path: "/giveaways",
    element: <Giveaways />,
  },
  {
    path: "/iso",
    element: <ISOInventory />,
  },
  {
    path: "/sellers/*",
    element: <SellerManager />,
  },
  {
    path: "/credits",
    element: <CreditsManager />,
  },
  {
    path:"/generation/*", 
    element: <ListingManager/>
  }
]);

const AppContainer = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async () => {
      setLoading(false);
      if (auth.currentUser) {
        try {
          await get<any>("/admin");
          if (window.location.pathname === "/login") {
            window.location.replace("/");
          }
        } catch (error) {
          console.warn("Not Admin Account");
          auth.signOut();
        }
      } else if (window.location.pathname !== "/login") {
        window.location.replace("/login");
      }
    });

    return unsubscribe;
  }, []);

  const theme = extendTheme({
    // components : {
    //   Box: {
    //     baseStyle: {
    //       borderRadius: '20px',
    //     }
    //   }
    // }
  });

  return loading ? (
    <Loading />
  ) : (
    <URLSearchParamsProvider>
      <ReloadContextProvider>
        <ChakraProvider theme={theme}>
          <ConfigProvider
            componentSize="large"
            theme={{
              token: {
                fontFamily: "Manrope",
                colorText: "#323232",
                colorLink: "#2B78E4",
              },
            }}
          >
            <Watermark content={EnvironmentName}>
              <RouterProvider router={router} />
            </Watermark>
          </ConfigProvider>
        </ChakraProvider>
      </ReloadContextProvider>
    </URLSearchParamsProvider>
  );
};

const FirebaseContainer = () => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    initializeApp(FirebaseConfig);
    setInitialized(true);
  }, []);

  return initialized ? <AppContainer /> : null;
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContainer />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
