import _ from "lodash";
import { 
  Box, 
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Text,
  HStack,
  theme,
  VStack
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DiscountGateway } from "../../api/DiscountGateway";
import { Discount } from "../../types";
import React from "react";

import Dayjs from "dayjs";

const AdvancedFormat = require('dayjs/plugin/advancedFormat');
const Utc = require('dayjs/plugin/utc')
const Timezone = require('dayjs/plugin/timezone')
Dayjs.extend(Utc);
Dayjs.extend(Timezone);
Dayjs.extend(AdvancedFormat);

export const DiscountDetail = ({
  discount,
  handleClick,
  selectedDiscount,
}: {
  discount: Discount;
  handleClick: (discount: Discount) => void;
  selectedDiscount: Discount | undefined;
}) => {
  return (
    <Box
      w="100%"
      //h="130px"
      bg={theme.colors.white}
      padding="5px"
      borderRadius={theme.radii.md}
      borderWidth="2px"
      borderStyle={
        selectedDiscount && selectedDiscount.id === discount.id ? "solid" : "none"
      }
      borderColor={theme.colors.blue[400]}
      onClick={() => {
        handleClick(discount);
      }}
    >
      <Grid
        h="100%"
        w="100%"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={1}
        pt="3"
      >
        <GridItem rowSpan={1} colSpan={3}>
          <Heading as="h3" size="sm" textAlign={"right"}>
            {discount.id}
          </Heading>
        </GridItem>
        <GridItem rowSpan={1} colSpan={5} pt="4">
          <HStack>
            <Text fontSize="sm" align="right">
              {discount.type}
            </Text>
            <Text fontSize="sm" align="right">
              {discount.amount}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize="xs" align={"right"}>
              Starts: {discount.startsAt}
            </Text>
            <Text fontSize="xs" align={"right"}>
              At: {Dayjs(discount.startsAt).format("h:mm:ss a z")}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize="xs" align={"right"}>
              Ends: {discount.expiresAt}
            </Text>
            <Text fontSize="xs" align={"right"}>
              At: {Dayjs(discount.expiresAt).format("h:mm:ss a z")}
            </Text>
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  );
};
