import {
  DependencyList,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

const reloadReducer = (count: number) => count + 1;
export function usePromise<T>(action: () => Promise<T>, deps?: DependencyList) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<T>();
  const [count, reload] = useReducer(reloadReducer, 0);

  const realDeps = deps ? deps.concat([count]) : [count];
  useEffect(() => {
    let unmount = false;
    const fetch = async () => {
      try {
        setLoading(true);
        const data = await action();

        if (!unmount) {
          setData(data);
          setError(undefined);
        }
      } catch (error: any) {
        if (!unmount) {
          setError(error.message ?? "Failed");
        }
      } finally {
        if (!unmount) {
          setLoading(false);
        }
      }
    };
    fetch();
    return () => {
      unmount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, realDeps);

  return { loading, data, error, reload };
}

export function useOnPromise<I extends any[], O>(
  action: (...input: I) => Promise<O>
) {
  const [loading, setLoading] = useState(false);
  const onPromise = useCallback(
    async (...i: I) => {
      try {
        setLoading(true);
        return await action(...i);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [action]
  );
  return { loading, onPromise };
}
