import { get, put } from ".";
import { IISO } from "../types/IISO";
import {
  IPaginationServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export const ISOGateway = {
  getISOs: async (params?: {
    take?: number;
    sort?: string;
  }): Promise<IISO[]> => {
    const { take = 200, sort = "createdAt:desc" } = params ?? {};
    try {
      const res = await get<IPaginationServiceResponse<IISO[]>>("/admin/iso", {
        take,
        sort,
        deleted: false,
      });
      return res.payload.data ?? [];
    } catch (err) {
      throw new Error("Could not get iso inventory");
    }
  },
  updateISO: async (id: string, data: Partial<IISO>): Promise<void> => {
    try {
      await put<IServiceResponse<void>>(`/admin/iso/${id}`, data);
    } catch (err) {
      throw new Error("Could not update iso inventory: " + id);
    }
  },
};
