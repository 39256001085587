import { Breadcrumb, Row } from "antd";
import { Link } from "react-router-dom";
import { AddCollectionButton } from "./components/AddCollection";
import { CollectionTable } from "./components/CollectionTable";

type Props = {};

export const Collections = (props: Props) => {
  return (
    <div className="page-container">
      <Row justify="space-between" align="middle">
        <Breadcrumb className="md:text-h2">
          <Breadcrumb.Item>
            <Link to="/" className="md:!h-9">
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Manage collections</Breadcrumb.Item>
        </Breadcrumb>
        <AddCollectionButton />
      </Row>
      <p className="text-h2 mt-8">Existing collections</p>
      <CollectionTable />
    </div>
  );
};
