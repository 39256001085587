import { Button } from "@chakra-ui/button";
import { Box, Center, Heading, HStack, VStack } from "@chakra-ui/layout";
import {
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import { getAuth } from "firebase/auth";
import { useState } from "react";
import "react-activity/dist/library.css";
import { CSVLink } from "react-csv";
import DateTimePicker from "react-datetime-picker";
import { useNavigate } from "react-router-dom";
import "../node_modules/video-react/dist/video-react.css";
import { get } from "./api";
import { Discounts } from "./components/Discounts";
import MarketplaceOrdersModal from "./components/MarketplaceOrdersModal";
import NotificationsModal from "./components/NotificationsModal";

function App() {
  const navigate = useNavigate();

  //INPUTS
  const [userQuery, setUserQuery] = useState("");

  const [orderData, setOrderData] = useState("");

  const {
    isOpen: isDiscountModalOpen,
    onOpen: onDiscountModalOpen,
    onClose: onDiscountModalClose,
  } = useDisclosure();

  const {
    isOpen: isMarketplaceOrdersModalOpen,
    onOpen: onMarketplaceOrdersModalOpen,
    onClose: onMarketplaceOrdersModalClose,
  } = useDisclosure();

  const {
    isOpen: isNotificationsModalOpen,
    onOpen: onNotificationsModalOpen,
    onClose: onNotificationsModalClose,
  } = useDisclosure();

  const {
    isOpen: isOrdersModalOpen,
    onOpen: onOrdersModalOpen,
    onClose: beforeOnOrdersModalClose,
  } = useDisclosure();

  const onOrdersModalClose = () => {
    setOrderData("");
    beforeOnOrdersModalClose();
  };

  const [ordersLoading, setOrdersLoading] = useState(false);
  const [orderFrom, onChangeOrderFrom] = useState(
    new Date(Date.now() - 1000 * 60 * 60 * 24)
  );
  const [orderTo, onChangeOrderTo] = useState(new Date());

  const logout = async () => {
    const auth = getAuth();
    await auth.signOut();
  };

  return (
    <VStack
      w="100vw"
      h="100vh"
      padding={"10px"}
      overflow={"hidden"}
      position={"relative"}
    >
      <MarketplaceOrdersModal
        isMarketplaceOrdersModalOpen={isMarketplaceOrdersModalOpen}
        onMarketplaceOrdersModalClose={onMarketplaceOrdersModalClose}
        selectUser={setUserQuery}
      />
      <NotificationsModal
        isNotificationsModalOpen={isNotificationsModalOpen}
        onNotificationsModalClose={onNotificationsModalClose}
      />
      <Modal isOpen={isOrdersModalOpen} onClose={onOrdersModalClose} size="xl">
        <ModalOverlay />
        <ModalContent width="600px">
          <ModalHeader>Download Orders</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <HStack>
                <FormLabel>From</FormLabel>
                <DateTimePicker
                  value={orderFrom}
                  onChange={onChangeOrderFrom}
                />
              </HStack>
              <HStack>
                <FormLabel>To</FormLabel>
                <DateTimePicker value={orderTo} onChange={onChangeOrderTo} />
              </HStack>

              <Button
                onClick={async () => {
                  setOrdersLoading(true);
                  const res = await get<string>(`/admin/orders`);
                  console.log("DOWNLOAD RES", res);
                  setOrderData(res);
                }}
              >
                Fetch Orders
              </Button>
              {orderData && (
                <CSVLink filename="orders.csv" data={orderData}>
                  Download me
                </CSVLink>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onOrdersModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDiscountModalOpen}
        onClose={onDiscountModalClose}
        size="full"
      >
        <ModalOverlay />
        <ModalContent width="1400px">
          <ModalHeader>Discounts</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Discounts handleClose={onDiscountModalClose} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onDiscountModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box w="100%" h="6vh" bg={theme.colors.gray[100]}>
        <Center height="100%">
          <Heading marginLeft={"auto"} size="sm">
            {" "}
            ⚠️ Seller Management Tool
          </Heading>
          <Button
            colorScheme={"red"}
            onClick={logout}
            margin={theme.space["2"]}
            marginLeft={"auto"}
          >
            Logout
          </Button>
        </Center>
      </Box>
      <div className="p-3 overflow-auto w-full max-w-xl">
        <VStack overflowY={"hidden"}>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={() => navigate("/giveaways")}
            size="sm"
          >
            Manage Giveaways
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={onOrdersModalOpen}
            size="sm"
          >
            Download Orders
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={onDiscountModalOpen}
            size="sm"
          >
            Discounts
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={onMarketplaceOrdersModalOpen}
            size="sm"
          >
            Marketplace Orders
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={() => navigate("/collections")}
            size="sm"
          >
            Product Collections
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={() => navigate("/inventory")}
            size="sm"
          >
            Inventory Tool
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={onNotificationsModalOpen}
            size="sm"
          >
            Notifications Tool
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={() => navigate("/iso")}
            size="sm"
          >
            ISO inventory
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={() => navigate("/sellers")}
            size="sm"
          >
            Seller Manager
          </Button>
          <Button
            w="100%"
            colorScheme={"blue"}
            onClick={() => navigate("/credits")}
            size="sm"
          >
            Credits Manager
          </Button>
        </VStack>
      </div>
    </VStack>
  );
}

export default App;
