import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Popconfirm,
  Row,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { isEqual, pick, pickBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DropGateway } from "../../../api/DropGateway";
import { AutoFloatButton } from "../../../components/AutoFloatButton";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IMarketplaceProduct } from "../../../types";
import { IProductDrop } from "../../../types/IProductDrop";
import { useMobile } from "../../../utils/useMobile";
import { useOnPromise } from "../../../utils/usePromise";
import { useAutoScrollTop } from "../../../utils/useScroll";
import { ListItems } from "../../collections/components/AddCollection";
import { Video } from "../../giveaways/components/AddGiveaway";
import { Description, Title } from "../../inventory/components/AddProduct";

type Props = {
  open: boolean;
  onClose: () => void;
  drop?: IProductDrop;
  shopId: string;
};

type DropInput = {
  name: string;
  description: string;
  startsAt: Dayjs;
  endsAt: Dayjs;
  videos?: { url: string }[];
};

const Times = () => {
  return (
    <Row>
      <Col flex="auto">
        <p className="text-h3 mb-3">Start time</p>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const endsAt = getFieldValue("endsAt") as Dayjs | undefined;
            return (
              <Form.Item
                name="startsAt"
                rules={[{ required: true, message: "Please input start time" }]}
              >
                <DatePicker
                  showTime
                  disabledDate={(date) =>
                    endsAt != null && date.isAfter(endsAt)
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col flex="auto">
        <p className="text-h3 mb-3">End time</p>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const startsAt = getFieldValue("startsAt") as Dayjs | undefined;
            return (
              <Form.Item
                name="endsAt"
                rules={[{ required: true, message: "Please input end time" }]}
              >
                <DatePicker
                  showTime
                  disabledDate={(date) =>
                    startsAt != null && date.isBefore(startsAt)
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

export const EditDrop = (props: Props) => {
  const { open, onClose, drop, shopId } = props;

  const { reload } = useReloadContext() ?? {};
  const [selected, setSelected] = useState<IMarketplaceProduct[]>(
    drop?.items ?? []
  );

  const [form] = Form.useForm<DropInput>();

  const initialValue = useMemo(
    () =>
      drop && {
        ...drop,
        startsAt: dayjs(drop.startsAt),
        endsAt: dayjs(drop.endsAt),
        videos: drop.videoURL ? [{ url: drop.videoURL }] : undefined,
      },
    [drop]
  );

  useEffect(() => {
    if (open) {
      if (initialValue) {
        form.setFieldsValue(initialValue);
        setSelected(initialValue.items ?? []);
      } else {
        form.resetFields();
        setSelected([]);
      }
    }
  }, [form, open, initialValue]);

  const { onPromise, loading } = useOnPromise(async () => {
    const data = await form.validateFields();

    const { startsAt, endsAt, videos, ...rest } = data;

    if (selected.length < 1) {
      message.error("Please select at least 4 items");
      return;
    }

    if (drop) {
      await DropGateway.updateDrop(
        {
          ...rest,
          id: drop.id,
          shopId,
          videoURL: videos?.[0].url,
          startsAt: startsAt.toDate().toISOString(),
          endsAt: endsAt.toDate().toISOString(),
          photoURLs: selected[0].photoURLs,
        },
        selected.map((item) => item.id)
      );
    } else {
      await DropGateway.createDrop(
        {
          ...rest,
          shopId,
          videoURL: videos?.[0].url,
          startsAt: startsAt.toDate().toISOString(),
          endsAt: endsAt.toDate().toISOString(),
          photoURLs: selected[0].photoURLs,
        },
        selected.map((item) => item.id)
      );
    }
    reload?.();
    onClose();
  });

  useAutoScrollTop("collection-items-selected", [open]);

  const isActive = useMemo(
    () =>
      drop &&
      dayjs(drop.startsAt).isBefore(dayjs()) &&
      dayjs(drop.endsAt).isAfter(dayjs()),
    [drop]
  );
  const isMobile = useMobile();

  return (
    <Modal
      open={open}
      width={isMobile ? "100%" : "80%"}
      cancelText="Cancel"
      okText="Save"
      onCancel={(e) => {
        if (
          (e.target as any).className === "ant-modal-wrap" ||
          (e as any).key === "Escape"
        ) {
          const currentValue = form.getFieldsValue();
          if (drop) {
            const { startsAt, endsAt, ...rest } = currentValue;
            rest.videos = rest.videos?.map((item) => ({
              url: item.url,
            }));
            const originalValue = pick(initialValue, Object.keys(rest));

            if (
              !isEqual(originalValue, rest) ||
              !startsAt.isSame(initialValue?.startsAt) ||
              !endsAt.isSame(initialValue?.endsAt)
            ) {
              Modal.confirm({
                title: "Are you sure you want to close without saving changes",
                onOk: onClose,
              });
              return;
            }
          } else if (
            Object.keys(pickBy(currentValue)).length > 0 ||
            selected.length > 0
          ) {
            Modal.confirm({
              title: "Are you sure you want to close without saving changes",
              onOk: onClose,
            });
            return;
          }
        }
        onClose();
      }}
      onOk={onPromise}
      confirmLoading={loading}
      title={drop ? "Drop detail" : "Create drop"}
    >
      <Form form={form}>
        <Row justify="end" align="middle">
          {isActive && (
            <Popconfirm
              title={
                <p className="text-h3 mb-3">Are you sure to end this drop?</p>
              }
              icon={null}
              okText="Yes, end this drop"
              cancelText="No, don't end"
              okButtonProps={{ danger: true }}
              overlayClassName="max-w-[571px]"
              onConfirm={async () => {
                try {
                  await DropGateway.endDrop(drop!);
                  onClose();
                  reload?.();
                } catch (e) {
                  message.error("Failed");
                }
              }}
            >
              <Button danger type="primary">
                End drop
              </Button>
            </Popconfirm>
          )}
        </Row>

        <Title placeholder="Enter title" />
        <Description placeholder="Add giveaway description here" />
        <Video />
        <Times />
        <ListItems
          selected={selected}
          onChange={setSelected}
          title={`Add products to drop (${selected.length} selected)`}
          min={1}
        />
      </Form>
    </Modal>
  );
};

export const AddDropButton = (props: { shopId: string }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AutoFloatButton
        type="primary"
        onClick={() => setOpen(true)}
        description="Create new drop"
      />
      <EditDrop
        open={open}
        onClose={() => setOpen(false)}
        shopId={props.shopId}
      />
    </>
  );
};
