import React, { ReactNode, useContext, useEffect, useState } from 'react';

type ReloadFunc = (interval?: number) => void;

const useReload = () => {
  const [reload, setReload] = useState<ReloadFunc>(() => {});

  return { reload, setReload };
};

const ReloadContext = React.createContext<ReturnType<typeof useReload>>(
  null as any,
);

export const useReloadContext = (reload?: ReloadFunc) => {
  const data = useContext(ReloadContext);
  useEffect(() => {
    if (reload) {
      data.setReload(() => reload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);
  return data as ReturnType<typeof useReload> | undefined;
};

export const ReloadContextProvider = React.memo(
  (props: { children: ReactNode }) => {
    const value = useReload();
    return (
      <ReloadContext.Provider value={value}>
        {props.children}
      </ReloadContext.Provider>
    );
  },
);
