import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Spacer,
  VStack,
  Text
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ProductGateway } from "../../api/ProductGateway";
import { Product, User } from "../../types";
import moment from "moment";
import { get, post } from "../../api";
import { IServiceResponse } from "../../types/IServiceResponse";
import { UserGateway } from "../../api/UserGateway";
import _ from "lodash"

const MarketplaceOrderBox = ({
  order,
  selectUser,
  refetch
}: {
  order: any;
  selectUser: (query: string) => void;
  refetch: () => Promise<void>
}) => {
  const [product, setProduct] = useState<any>();
  const [buyer, setBuyer] = useState<User>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    get<IServiceResponse<any>>(`/marketplace/products/${order.productId}`)
    .then((res) => {
      if (res.payload && res.success) {
        setProduct(res.payload);
      }
    });
    order.userId &&
      UserGateway.findById(order.userId).then((userRes) => {
        if (userRes.payload && userRes.success) {
          setBuyer(userRes.payload);
        }
      });
  }, []);

  const handleChargeOrder = async () => {
    setLoading(true)
    await post<IServiceResponse<any>>(`/marketplace/orders/${order.id}/charge`, {});
    await refetch();
    setLoading(false)
  };

  return (
    <Box bg="gray.100" padding={"2"} borderRadius="md" alignSelf={"stretch"}>
      <HStack>
        <VStack align="flex-start" flex={1}>
          <Text fontWeight={600} decoration="underline">
            <a href={product?.url} target="_blank" rel="noreferrer">
              {product?.name}
            </a>
          </Text>
          <HStack>
            <Text>by {buyer?.username} </Text>
          </HStack>
          <HStack>
            <Text>{new Date(order.createdAt).toLocaleDateString()}</Text>
            <Text>@ {new Date(order.createdAt).toLocaleTimeString()}</Text>
          </HStack>
          <HStack>
            <Text>Sale Price:</Text>
            <Text fontWeight={"600"}>${order.amount}</Text>
            <Text>{order.status}</Text>
          </HStack>
          <HStack justifyContent={"flex-end"} width={"100%"}>
            {/* <Box>{order.count} entries</Box> */}
            <Box>
              <Button
                alignSelf={"flex-end"}
                colorScheme={"blue"}
                onClick={handleChargeOrder}
                disabled={order.status === "CONFIRMED" || loading}
              >
                charge order
              </Button>
            </Box>
            <Box>
              <Button
                alignSelf={"flex-end"}
                colorScheme={"blue"}
                onClick={() =>
                  buyer?.username && selectUser(buyer?.phoneNumber)
                }
              >
                see buyer
              </Button>
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default MarketplaceOrderBox;
