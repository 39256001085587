import { PlusCircleTwoTone } from "@ant-design/icons";
import { Form, Upload } from "antd";
import { uploadFileProperties } from "../../utils/file";

export const UploadButton = () => (
  <div>
    <PlusCircleTwoTone />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

export const PhotosInput = (props: { name?: string }) => {
  const { name = "photoURLs" } = props;
  return (
    <Form.Item
      noStyle
      shouldUpdate={(pre, next) =>
        (pre?.[name] ?? []).length !== (next?.[name] ?? []).length
      }
    >
      {({ getFieldValue }) => (
        <Form.Item
          name={name}
          rules={[
            {
              required: true,
              message: "Please upload photos",
            },
          ]}
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            listType="picture-card"
            {...uploadFileProperties({
              maxCount: 5,
              currentCount: (getFieldValue(name) ?? []).length,
            })}
            multiple
          >
            {(getFieldValue(name) ?? []).length < 5 ? <UploadButton /> : null}
          </Upload>
        </Form.Item>
      )}
    </Form.Item>
  );
};
