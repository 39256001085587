import { get, post } from ".";
import { User } from "../types";
import { ICredit } from "../types/ICredit";
import {
  IPaginationServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export const CreditGateway = {
  getUserCredits: async (userId: string) => {
    try {
      const res = await get<IServiceResponse<ICredit[]>>(
        `/users/${userId}/credits`,
        {}
      );
      return res.payload;
    } catch (err) {
      throw new Error("Could not get user credits");
    }
  },
  addCreditToUser: async (userId: string, amount: number) => {
    try {
      const res = await post<IPaginationServiceResponse<User[]>>(
        `/users/${userId}/credit`,
        { deal: { amount } }
      );
      return res.payload;
    } catch (err: any) {
      throw new Error(
        "Could not add credit to user. " + err?.response?.data?.message
      );
    }
  },
};
