import { Form, Select } from "antd";

export const SizeOptions = [
  { label: "XXS", value: 2 },
  { label: "XS", value: 3 },
  { label: "S", value: 4 },
  { label: "M", value: 5 },
  { label: "L", value: 6 },
  { label: "XL", value: 7 },
  { label: "XXL", value: 8 },
  { label: "XXXL", value: 9 },
  { label: "XXXXL", value: 10 },
  { label: "Unknown", value: 0 },
];

export const SizeInput = () => {
  return (
    <div>
      <p className="text-h3">Size</p>
      <Form.Item
        name="size"
        rules={[
          {
            required: true,
            message: "Please input size",
          },
        ]}
      >
        <Select
          className="mt-4 !w-40"
          placeholder="Select size"
          options={SizeOptions}
        />
      </Form.Item>
    </div>
  );
};
