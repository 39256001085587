import { ShopOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  List,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { useState } from "react";
import { UserGateway } from "../../../api/UserGateway";
import { AutoFloatButton } from "../../../components/AutoFloatButton";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { usePagination } from "../../../utils/pagination";
import { useMobile } from "../../../utils/useMobile";
import { usePromise } from "../../../utils/usePromise";

type Props = {
  open: boolean;
  onClose: () => void;
};

const User = (props: {
  uid: string;
  avatar: string;
  name: string;
  isSeller: boolean;
  afterMake: () => void;
}) => {
  const { uid, avatar, name, isSeller, afterMake } = props;
  return (
    <Row
      justify="space-between"
      align="middle"
      className="border border-grey-10 rounded-lg my-1 py-4 pl-8 pr-4 max-md:!p-4 bg-white hover:bg-[#f5f5f5]"
    >
      <Space size={20}>
        <Avatar shape="square" size={85} src={avatar} />
        <Space>
          <Typography.Paragraph className="text-h3 !mb-0">
            {name}
          </Typography.Paragraph>
          {isSeller && <Tag>Seller</Tag>}
        </Space>
      </Space>
      {!isSeller && (
        <Popconfirm
          title="Are you sure you want to make this seller"
          onConfirm={async () => {
            try {
              await UserGateway.promoteUser(uid);
              afterMake();
            } catch (error) {}
          }}
        >
          <Button icon={<ShopOutlined />} type="link">
            Make seller
          </Button>
        </Popconfirm>
      )}
    </Row>
  );
};

const UserList = () => {
  const { page, pageSize, setPage, setPageSize } = usePagination();

  const {
    data,
    reload: refetch,
    loading,
  } = usePromise(
    () => UserGateway.getUsers({ page, pageSize }),
    [page, pageSize]
  );

  const total = data?.total;
  return (
    <List
      loading={loading}
      dataSource={data?.data ?? []}
      renderItem={(item) => (
        <User
          uid={item.id}
          avatar={item.photoURL ?? ""}
          name={item.username ?? ""}
          afterMake={refetch}
          isSeller={item.role === "SELLER"}
        />
      )}
      rowKey="id"
      pagination={{
        total,
        pageSize,
        current: page,
        onChange: (page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        },
      }}
    />
  );
};

const AddSeller = (props: Props) => {
  const { open, onClose } = props;
  const { reload } = useReloadContext() ?? {};
  const isMobile = useMobile();

  return (
    <Modal
      open={open}
      width={isMobile ? "100%" : "80%"}
      cancelText="Close"
      okText="Finish"
      onCancel={onClose}
      afterClose={reload}
      title="Add seller"
      destroyOnClose
      footer={
        <Row justify="end">
          <Button onClick={onClose}>Close</Button>
        </Row>
      }
    >
      <UserList />
    </Modal>
  );
};

export const AddSellerButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AutoFloatButton
        type="primary"
        onClick={() => setOpen(true)}
        description="Add seller"
      />
      <AddSeller open={open} onClose={() => setOpen(false)} />
    </>
  );
};
