import { get, post, put } from ".";
import { User } from "../types";
import {
  failureServiceResponse,
  IPaginationServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export const UserGateway = {
  getUsers: async (params?: { page?: number; pageSize?: number }) => {
    const { page = 1, pageSize = 20 } = params ?? {};
    const skip = (page - 1) * pageSize;
    try {
      const res = await get<IPaginationServiceResponse<User[]>>(
        "/admin/users",
        { skip, take: pageSize, sort: "username:asc" }
      );
      return res.payload;
    } catch (err) {
      throw new Error("Could not get users");
    }
  },

  promoteUser: async (userId: string): Promise<IServiceResponse<{}>> => {
    try {
      const res = await put<IServiceResponse<{}>>(
        `/admin/users/${userId}/promote`,
        {}
      );
      return res;
    } catch (err) {
      return failureServiceResponse("Could not promote user " + userId);
    }
  },

  findById: async (userId: string): Promise<IServiceResponse<User>> => {
    try {
      const res = await get<IServiceResponse<User>>(`/admin/user/${userId}`);
      return res;
    } catch (err) {
      return failureServiceResponse("Could not follow Shop");
    }
  },

  followShop: async (shopId: string): Promise<IServiceResponse<{}>> => {
    try {
      const res = await post<IServiceResponse<{}>>("/user/follow_shop", {
        shopId,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not follow Shop");
    }
  },

  unfollowShop: async (shopId: string): Promise<IServiceResponse<{}>> => {
    try {
      const res = await post<IServiceResponse<{}>>("/user/unfollow_shop", {
        shopId,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not unfollow Shop");
    }
  },

  isFollowingShop: async (
    shopId: string
  ): Promise<IServiceResponse<{ following: boolean }>> => {
    try {
      const res = await post<IServiceResponse<{ following: boolean }>>(
        "/user/is_following_shop",
        {
          shopId,
        }
      );
      return res;
    } catch (err) {
      return failureServiceResponse("Could not determine if following Shop");
    }
  },

  findByQuery: async (query: string): Promise<IServiceResponse<User>> => {
    try {
      const res = await post<IServiceResponse<User>>("/user/query", {
        query,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not find the user");
    }
  },
};
