import { EditFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Upload,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { isEqual, pick, pickBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { RaffleGateway } from "../../../api/RaffleGateway";
import { AutoFloatButton } from "../../../components/AutoFloatButton";
import {
  PhotosInput,
  UploadButton,
} from "../../../components/FormInput/PhotosInput";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IRaffle } from "../../../types/IRaffle";
import { uploadFileProperties } from "../../../utils/file";
import { useMobile } from "../../../utils/useMobile";
import { useOnPromise } from "../../../utils/usePromise";
import { SellerPicker } from "../../inventory/components/formItems/SellerInput";

type Props = {
  open: boolean;
  onClose: () => void;
  title?: string;
  collection?: IRaffle;
};

interface RafileInput {
  title: string;
  background: { url: string }[];
  description?: string;
  entryCutoff: Dayjs;
  raffleTime: Dayjs;
  estimatedValue?: number;
  image?: { url: string }[];
  photos?: { url: string }[];
  sponsorId?: string;
  videos?: { url: string }[];
}

const Title = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Title</p>
      <Form.Item
        name="title"
        rules={[{ required: true, message: "Please input collection name" }]}
      >
        <Input placeholder="Title of giveaway" />
      </Form.Item>
    </>
  );
};

const UserPicker = (props: {}) => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Giveaway sponsor (optional)</p>
      <SellerPicker name="sponsorId" placeholder="Enter username" allowClear />
    </>
  );
};

const EstimatedValue = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Estimated value (optional)</p>
      <Form.Item name="estimatedValue">
        <InputNumber
          min={0}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          addonBefore="$"
        />
      </Form.Item>
    </>
  );
};

const Description = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Description</p>
      <Form.Item name="description">
        <Input.TextArea placeholder="Add giveaway description here" />
      </Form.Item>
    </>
  );
};

interface TimesProps {
  startName?: string;
  endName?: string;
  endTitle?: string;
  endMessage?: string;
  titleClassName?: string;
}

export const Times = (props: TimesProps) => {
  const {
    startName = "raffleTime",
    endName = "entryCutoff",
    endTitle = "Drawing time",
    endMessage = "Please input drawing time",
    titleClassName = "text-h3 mb-3",
  } = props;
  return (
    <Row>
      <Col flex="auto">
        <p className={titleClassName}>Start time</p>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const entryCutoff = getFieldValue(endName) as Dayjs | undefined;
            return (
              <Form.Item
                name={startName}
                rules={[{ required: true, message: "Please input start time" }]}
              >
                <DatePicker
                  showTime
                  disabledDate={(date) =>
                    entryCutoff != null && date.isAfter(entryCutoff)
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col flex="auto">
        <p className={titleClassName}>{endTitle}</p>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const raffleTime = getFieldValue(startName) as Dayjs | undefined;
            return (
              <Form.Item
                name={endName}
                rules={[{ required: true, message: endMessage }]}
              >
                <DatePicker
                  showTime
                  disabledDate={(date) =>
                    raffleTime != null && date.isBefore(raffleTime)
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

const HeroImage = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Build your giveaway hero image below</p>
      <p className="text-h4 mb-3">Step 1: Select background</p>
      <Form.Item
        name="background"
        valuePropName="fileList"
        rules={[{ required: true, message: "Please set background image" }]}
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload
          listType="picture-card"
          maxCount={1}
          {...uploadFileProperties({})}
        >
          <UploadButton />
        </Upload>
      </Form.Item>
      <p className="text-h4 mb-3">Step 2: Upload sharesheet image</p>
      <Form.Item
        name="image"
        valuePropName="fileList"
        rules={[{ required: true, message: "Please set sharesheet image" }]}
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload
          listType="picture-card"
          maxCount={1}
          {...uploadFileProperties({})}
        >
          <UploadButton />
        </Upload>
      </Form.Item>
    </>
  );
};

export const Video = () => {
  return (
    <Space direction="vertical" className="w-[256px]">
      <p className="text-h4 mb-3">Video (Optional)</p>
      <Form.Item
        name="videos"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload
          listType="picture-card"
          maxCount={1}
          {...uploadFileProperties({ accept: "video/mp4" })}
        >
          <UploadButton />
        </Upload>
      </Form.Item>
    </Space>
  );
};

const AdditionalMedia = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Upload addditional media</p>
      <Space wrap>
        <Video />
        <Space direction="vertical">
          <p className="text-h4 mb-3">Images (Max 5)</p>
          <PhotosInput name="photos" />
        </Space>
      </Space>
    </>
  );
};

export const EditGiveaway = (props: Props) => {
  const { open, onClose, collection } = props;
  const { reload } = useReloadContext() ?? {};

  const [form] = Form.useForm<RafileInput>();

  const initialValue = useMemo(
    () =>
      collection && {
        ...collection,
        videos: collection.videoURL
          ? [{ url: collection.videoURL }]
          : undefined,
        photos: collection.photoURLs
          ? collection.photoURLs.map((url) => ({ url }))
          : undefined,
        background: collection.backgroundURL
          ? [{ url: collection.backgroundURL }]
          : undefined,
        entryCutoff: dayjs(collection.entryCutoff),
        raffleTime: dayjs(collection.raffleTime),
        image: collection.sharePhotoURL
          ? [{ url: collection.sharePhotoURL }]
          : undefined,
        sponsorId: collection.sponsor?.name,
      },
    [collection]
  );

  useEffect(() => {
    if (open) {
      if (initialValue) {
        form.setFieldsValue(initialValue);
      } else {
        form.resetFields();
      }
    }
  }, [form, open, initialValue]);

  const { onPromise, loading } = useOnPromise(async () => {
    try {
      const {
        videos,
        image,
        photos,
        background,
        entryCutoff,
        raffleTime,
        ...rest
      } = await form.validateFields();

      if (entryCutoff.isBefore(raffleTime)) {
        message.error("Drawing time should not before start time");
        return;
      }

      let success = false;
      if (collection) {
        const data = await RaffleGateway.updateRaffle(collection.id, {
          ...rest,
          sponsorId:
            rest.sponsorId === collection.sponsor?.name
              ? collection.sponsor?.id
              : rest.sponsorId,
          videoURL: videos?.[0].url,
          photoURLs: photos?.map((item) => item.url),
          sharePhotoURL: image?.[0].url,
          backgroundURL: background?.[0].url,
          entryCutoff: entryCutoff.toDate().toISOString(),
          raffleTime: raffleTime.toDate().toISOString(),
        });
        success = data.success;
      } else {
        const data = await RaffleGateway.createRaffle({
          ...rest,
          videoURL: videos?.[0].url,
          photoURLs: photos?.map((item) => item.url),
          sharePhotoURL: image?.[0].url,
          backgroundURL: background?.[0].url,
          entryCutoff: entryCutoff.toDate().toISOString(),
          raffleTime: raffleTime.toDate().toISOString(),
        });
        success = data.success;
      }
      if (success) {
        reload?.();
        onClose();
      }
    } catch (error) {
      console.log({ error });
    }
  });
  const isMobile = useMobile();

  return (
    <Modal
      open={open}
      width={isMobile ? "100%" : "80%"}
      cancelText="Cancel"
      okText="Save"
      onCancel={(e) => {
        if (
          (e.target as any).className === "ant-modal-wrap" ||
          (e as any).key === "Escape"
        ) {
          const currentValue = form.getFieldsValue();
          if (collection) {
            const { entryCutoff, raffleTime, ...rest } = currentValue;
            rest.background = rest.background?.map((item) => ({
              url: item.url,
            }));
            rest.image = rest.image?.map((item) => ({ url: item.url }));
            rest.photos = rest.photos?.map((item) => ({ url: item.url }));
            rest.videos = rest.videos?.map((item) => ({ url: item.url }));
            const originalValue = pick(initialValue, Object.keys(rest));

            if (
              !isEqual(originalValue, rest) ||
              !entryCutoff.isSame(initialValue?.entryCutoff) ||
              !raffleTime.isSame(initialValue?.raffleTime)
            ) {
              Modal.confirm({
                title: "Are you sure you want to close without saving changes",
                onOk: onClose,
              });
              return;
            }
          } else if (Object.keys(pickBy(currentValue)).length > 0) {
            Modal.confirm({
              title: "Are you sure you want to close without saving changes",
              onOk: onClose,
            });
            return;
          }
        }
        onClose();
      }}
      onOk={onPromise}
      confirmLoading={loading}
      title={collection ? "Giveaway details" : "Add giveaway"}
    >
      <Form form={form}>
        <Row justify="end" align="middle">
          {collection && (
            <Popconfirm
              title={
                <p className="text-h3 mb-3">
                  Are you sure to delete this item?
                </p>
              }
              icon={null}
              okText="Yes, delete this giveaway"
              cancelText="No, don't delete"
              okButtonProps={{ danger: true }}
              overlayClassName="max-w-[571px]"
              onConfirm={async () => {
                try {
                  await RaffleGateway.delRaffle(collection.id);
                  onClose();
                  reload?.();
                } catch (e) {
                  message.error("Failed");
                }
              }}
            >
              <Button danger type="primary">
                Delete giveaway
              </Button>
            </Popconfirm>
          )}
        </Row>
        <Title />
        <UserPicker />
        <EstimatedValue />
        <Description />
        <Times />
        <HeroImage />
        <AdditionalMedia />
      </Form>
    </Modal>
  );
};

export const AddGiveawayButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AutoFloatButton
        type="primary"
        onClick={() => setOpen(true)}
        description="Add new giveaway"
      />
      <EditGiveaway open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export const EditGiveawayButton = (props: { collection: IRaffle }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button type="link" onClick={() => setOpen(true)}>
        Edit giveaway
        <EditFilled />
      </Button>
      <EditGiveaway
        open={open}
        collection={props.collection}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
