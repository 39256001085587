import { Route, Routes } from "react-router-dom";
import { ListingGeneration } from "./ListingGeneration";

type Props = {};

export const ListingManager = (props: Props) => {
  return (
    <Routes>
      <Route path=":generationId" element={<ListingGeneration/>} />
    </Routes>
  );
};
