import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAuth } from "firebase/auth";
import { getEnvironmentURL } from "../utils/EnvironmentUtils";

let baseURL = getEnvironmentURL();

// if () {
// const baseURL = "https://6a006059579a.ngrok.io";
// }

export async function http<T>(request: AxiosRequestConfig): Promise<T> {
  const auth = getAuth();
  const firebaseId = await auth.currentUser?.getIdToken();
  if (request.headers && firebaseId) {
    // request.headers.Authorization = `Bearer ${token}`;
    request.headers["x-api-token"] = firebaseId;
  }
  const response: AxiosResponse = await axios(request);
  return response.data as T;
}

/** The HTTP GET method requests a representation of the specified resource. */
export async function get<T>(url: string, params?: any): Promise<T> {
  return await http<T>({
    method: "GET",
    headers: { "Content-Type": "application/json" },
    url: params
      ? baseURL + url + "?" + new URLSearchParams(params).toString()
      : baseURL + url,
  });
}

/** Send data in the body of the HTTP Request */
export async function post<T>(url: string, body: any): Promise<T> {
  return await http<T>({
    data: body,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    url: baseURL + url,
  });
}

export async function put<T>(url: string, body: any): Promise<T> {
  return await http<T>({
    data: body,
    headers: { "Content-Type": "application/json" },
    method: "PUT",
    url: baseURL + url,
  });
}

export async function del<T>(url: string, body?: any): Promise<T> {
  return await http<T>({
    data: body,
    headers: { "Content-Type": "application/json" },
    method: "DELETE",
    url: baseURL + url,
  });
}

/**
 * The HTTP PUT request method creates a new resource or replaces
 * a representation of the target resource with the request payload.
 */
// export async function put<T>(url: string, body: any): Promise<T> {
//   return await http<T>({
//     data: body,
//     headers: { "Content-Type": "application/json" },
//     method: "PUT",
//     url: url,
//   });
// }

// /** The HTTP DELETE request method deletes the specified resource. */
// export async function remove<T>(url: string): Promise<T> {
//   return await http<T>({
//     method: "DELETE",
//     url: url,
//   });
// }

// const instance = axios.create({
//   baseURL: baseURL,
// });

// instance.interceptors.request.use(
//   async (config) => {
//     const token =
//       "eyJhbGciOiJIUzI1NiJ9.ODYwNjM5MzUzNA.HNhsLWxBln_vJCtpXMZHwnMUjSTyhEDdStYN0GnCfYA";
//     if (token && config.headers) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (err: any) => {
//     return Promise.reject(err);
//   }
// );

// export { instance };
