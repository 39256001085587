import {
  Button,
  Form,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Space,
} from "antd";
import dayjs from "dayjs";
import _, { isEqual, pick } from "lodash";
import { useEffect, useMemo } from "react";
import { MarketplaceGateway } from "../../../api/MarketplaceGateway";
import { ICreateMarketplaceProductInput } from "../../../api/MarketplaceGateway.type";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IMarketplaceProduct } from "../../../types";
import { formatDate } from "../../../utils/time";
import { useMobile } from "../../../utils/useMobile";
import { useOnPromise } from "../../../utils/usePromise";
import {
  Authentication,
  Description,
  Measurement,
  PriceSection,
  Tags,
  Title,
} from "./AddProduct";
import { PhotosInput } from "./formItems/PhotosInput";
import { SellerInput } from "./formItems/SellerInput";
import { SizeInput } from "./formItems/SizeInput";

type Props = {
  open: boolean;
  product: IMarketplaceProduct;
  onClose: () => void;
};

const SellStatus = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Sell status</p>
      <Form.Item name="available">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={false}>Sold</Radio>
          <Radio value={true}>Available</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

const ListingStatus = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Visibility</p>
      <Form.Item name="listed">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={false}>Unlisted</Radio>
          <Radio value={true}>Listed</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export const Source = ({ url }: { url: string }) => {
  return (
    <>
      <a href={url} target="_blank" rel="noreferrer">
        <Button type="primary" className="mr-2">
          Source
        </Button>
      </a>
    </>
  );
};

export const EditProduct = (props: Props) => {
  const { open, onClose, product } = props;
  const { reload } = useReloadContext() ?? {};

  const isMobile = useMobile();

  const { onPromise, loading } = useOnPromise(async () => {
    const { photoURLs, source, size, auctionStartsAt, auctionEndsAt, ...data } =
      await form.validateFields();

    await MarketplaceGateway.updateProduct(product.id, {
      ...data,
      photoURLs: photoURLs.map((item) => item.url),
      metadata: source != null ? { url: source } : undefined,
      price: Number(data.price ?? 9999999999),
      size: String(size),
      auctionStartPrice: !_.isNil(data.auctionStartPrice)
        ? Number(data.auctionStartPrice)
        : undefined,
      auctionStartsAt: auctionStartsAt?.toDate().toISOString(),
      auctionEndsAt: auctionEndsAt?.toDate().toISOString(),
    });
    reload?.();
    onClose();
  });

  const [form] = Form.useForm<ICreateMarketplaceProductInput>();

  const initialValue = useMemo(
    () => ({
      ...product,
      source: product.sourceURL,
      tagIds: product.tags?.map((item) => item.id),
      photoURLs: product.photoURLs?.map((url) => ({ url })),
      size: product.size != null ? Number(product.size) : undefined,
      discountAmount: product.discount
        ? product.discount.type === "PERCENT"
          ? product.discount.amount
          : Math.ceil(
              (Number(product.discount.amount) * 100) / Number(product.price)
            )
        : undefined,
      authStatus: product.authStatus ?? ("NOT_AUTHENTICATED" as const),
      width: product.width,
      height: product.height,
      auctionStartPrice: !!product.auction
        ? product.auction.startPrice
        : undefined,
      auctionStartsAt: !!product.auction
        ? dayjs(product.auction.startsAt)
        : undefined,
      auctionEndsAt: !!product.auction
        ? dayjs(product.auction.endsAt)
        : undefined,
    }),
    [product]
  );

  useEffect(() => {
    if (open) {
      form.setFieldsValue(initialValue);
    }
  }, [form, initialValue, open]);

  return (
    <Modal
      open={open}
      onOk={onPromise}
      confirmLoading={loading}
      onCancel={(e) => {
        if (
          (e.target as any).className === "ant-modal-wrap" ||
          (e as any).key === "Escape"
        ) {
          const currentValue = form.getFieldsValue();
          currentValue.photoURLs = currentValue.photoURLs.map((item) => ({
            url: item.url,
          }));
          const originalValue = pick(initialValue, Object.keys(currentValue));
          if (!isEqual(currentValue, originalValue)) {
            Modal.confirm({
              title: "Are you sure you want to close without saving changes",
              onOk: onClose,
            });
            return;
          }
        }
        onClose();
      }}
      okText="Save product"
      cancelText="Cancel changes"
      width={isMobile ? undefined : "60%"}
      title={`Product detail (${product.id})`}
      destroyOnClose
    >
      <Form form={form}>
        <Row justify="space-between" align="middle">
          <p className="text-body text-grey mt-2">
            Refreshed {formatDate(new Date(product.updatedAt))} • Last edited by
            User {product.lastEditor}
          </p>
          <Row>
            {product.sourceURL && <Source url={product.sourceURL} />}
            <Popconfirm
              title={
                <p className="text-h3 mb-3">
                  Are you sure to delete this item?
                </p>
              }
              icon={null}
              okText="Yes, delete this product"
              cancelText="No, don't delete"
              okButtonProps={{ danger: true }}
              overlayClassName="max-w-[571px]"
              onConfirm={async () => {
                try {
                  await MarketplaceGateway.deleteProduct(product.id);
                  onClose();
                  reload?.();
                } catch (e) {
                  message.error("Failed");
                }
              }}
            >
              <Button danger type="primary">
                Delete product
              </Button>
            </Popconfirm>
          </Row>
        </Row>
        <Space wrap>
          <Title />
          <SellStatus />
          <ListingStatus />
        </Space>
        <Description />
        <SellerInput />
        <PhotosInput maxCount={10} />
        <Tags defaultTags={product.tags} />
        <Authentication />
        <Row justify="space-between" className="mt-6" wrap>
          <SizeInput />
          <Measurement />
        </Row>
        <p className="text-h3 mt-6">Pricing</p>
        <PriceSection auction={initialValue.auctionStartPrice != null} />
      </Form>
    </Modal>
  );
};
