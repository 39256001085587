import { post } from "../api";
import { IServiceResponse } from "../types/IServiceResponse";

export default class MediaUploadUtils {
  static uploadMediaFromPath(file: File, uploadURL: string) {
    return fetch(uploadURL, {
      method: "PUT",
      // mode: "no-cors",
      body: file,
    });
  }
  static async uploadImage(file: File) {
    const data = await post<
      IServiceResponse<{ photoURL: string; uploadURL: string }>
    >("/admin/images", { key: "images", type: file.type });
    if (data.payload) {
      const { photoURL, uploadURL } = data.payload;
      await MediaUploadUtils.uploadMediaFromPath(file, uploadURL);
      return photoURL;
    }
  }
  static async uploadVideo(file: File) {
    const data = await post<
      IServiceResponse<{ videoURL: string; uploadURL: string }>
    >("/admin/videos", { key: "collections" });
    if (data.payload) {
      const { videoURL, uploadURL } = data.payload;
      await MediaUploadUtils.uploadMediaFromPath(file, uploadURL);
      return videoURL;
    }
  }
}
