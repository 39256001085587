import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Upload } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={isDragging ? "pointer-events-none" : undefined}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  );
};

export const DraggableUpload = (
  props: UploadProps & {
    value?: UploadFile[];
    onChange?: (value: UploadFile[]) => void;
  }
) => {
  const [fileList, setFileList] = useState<UploadFile[]>(props.value ?? []);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onFileListChange = (newFileList: UploadFile[]) => {
    setFileList(newFileList);
    props.onChange?.(newFileList);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = fileList.findIndex((i) => i.uid === active.id);
      const overIndex = fileList.findIndex((i) => i.uid === over?.id);
      const newFileList = arrayMove(fileList, activeIndex, overIndex);
      onFileListChange(newFileList);
    }
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    onFileListChange(newFileList);
  };

  useEffect(() => {
    setFileList(props.value ?? []);
  }, [props.value]);

  return (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext
        items={fileList.map((i) => i.uid)}
        strategy={verticalListSortingStrategy}
      >
        <Upload
          {...props}
          fileList={fileList}
          onChange={onChange}
          itemRender={(originNode, file) => (
            <DraggableUploadListItem originNode={originNode} file={file} />
          )}
          multiple
        />
      </SortableContext>
    </DndContext>
  );
};
