import { ToTopOutlined } from "@ant-design/icons";
import {
  Avatar,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { isEqual, pick, pickBy } from "lodash";
import { useEffect, useState } from "react";
import { CollectionGateway } from "../../../api/CollectionGateway";
import { AutoFloatButton } from "../../../components/AutoFloatButton";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IMarketplaceProduct } from "../../../types";
import { IProductCollection } from "../../../types/IProductCollection";
import { formatMoney } from "../../../utils/number";
import { useMobile } from "../../../utils/useMobile";
import { useOnPromise } from "../../../utils/usePromise";
import { useAutoScrollTop } from "../../../utils/useScroll";
import { InventoryTable } from "../../inventory";
import { EditProduct } from "../../inventory/components/EditProduct";
import { Search } from "../../inventory/components/Search";

type Props = {
  open: boolean;
  onClose: () => void;
  title?: string;
  collection?: IProductCollection;
};

const Title = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Name</p>
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please input collection name" }]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

const Description = () => {
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Description</p>
      <Form.Item name="description">
        <Input.TextArea />
      </Form.Item>
    </>
  );
};

const SelectedItem = (props: {
  item: IMarketplaceProduct;
  onChange: () => void;
  onMoveToFirst?: () => void;
}) => {
  const { item, onMoveToFirst } = props;
  const [open, setOpen] = useState(false);
  const price = Number(props.item.price);
  const discountedPrice =
    props.item.discountedPrice != null
      ? Number(props.item.discountedPrice)
      : undefined;
  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className="border border-grey-10 rounded-lg p-5 m-1 hover:bg-[#f5f5f5]"
        wrap={false}
      >
        <Col flex="none">
          <Space direction="vertical">
            <Checkbox checked onChange={props.onChange} />
            {onMoveToFirst ? (
              <Tooltip title="move to the first">
                <ToTopOutlined
                  className="text-blue text-link cursor-pointer"
                  onClick={onMoveToFirst}
                />
              </Tooltip>
            ) : null}
          </Space>
        </Col>
        <Col
          flex="auto"
          className="cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <Space>
            <Avatar
              shape="square"
              size={120}
              src={item.photoURLs[0]}
              className="ml-4"
            />
            <Space direction="vertical">
              <Typography.Paragraph
                ellipsis={{ rows: 2 }}
                className="text-h3"
                style={{ marginBottom: 0 }}
              >
                {item.name}
              </Typography.Paragraph>
              <Space>
                <Typography.Text
                  className="text-h4"
                  delete={discountedPrice != null && price !== discountedPrice}
                >
                  {formatMoney(price)}
                </Typography.Text>
                {discountedPrice != null && (
                  <Typography.Text className="text-h4">
                    {formatMoney(discountedPrice)}
                  </Typography.Text>
                )}
              </Space>
            </Space>
          </Space>
        </Col>
      </Row>
      <EditProduct
        open={open}
        onClose={() => setOpen(false)}
        product={props.item}
      />
    </>
  );
};

export const ListItems = (props: {
  selected: IMarketplaceProduct[];
  onChange: (value: IMarketplaceProduct[]) => void;
  title?: string;
  min?: number;
}) => {
  const {
    selected,
    onChange,
    title = `Item order in collection (${selected.length} selected)`,
    min = 4,
  } = props;

  return (
    <Tabs
      items={[
        {
          key: "selected",
          label:
            title ?? `Item order in collection (${selected.length} selected)`,
          children: (
            <>
              <List
                id="collection-items-selected"
                grid={{
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 2,
                  xl: 3,
                  xxl: 3,
                }}
                dataSource={selected}
                renderItem={(item, index) => (
                  <SelectedItem
                    item={item}
                    onChange={() =>
                      onChange(selected.filter((sel) => sel.id !== item.id))
                    }
                    onMoveToFirst={
                      index === 0
                        ? undefined
                        : () => {
                            const data = selected.filter(
                              (sel) => sel.id !== item.id
                            );
                            data.unshift(item);
                            onChange(data);
                          }
                    }
                  />
                )}
                className="h-[340px] overflow-auto"
                rowKey="id"
              />
              <div className="h-5">
                {selected.length < min && (
                  <Typography.Text type="danger">
                    Please select at least {min} items
                  </Typography.Text>
                )}
              </div>
            </>
          ),
        },
        {
          key: "all",
          label: "All",
          children: (
            <>
              <AddItems selected={selected} onChange={onChange} />
            </>
          ),
        },
      ]}
    />
  );
};

const AddItems = (props: {
  selected: IMarketplaceProduct[];
  onChange: (value: IMarketplaceProduct[]) => void;
}) => {
  return (
    <>
      <Row justify="space-between" align="middle" className="mt-6">
        <p className="text-h4">Select items</p>
        <Search />
      </Row>
      <InventoryTable className="h-[860px] overflow-auto" selection={props} />
    </>
  );
};

export const EditCollection = (props: Props) => {
  const { open, onClose, title = "Add collection", collection } = props;
  const { reload } = useReloadContext() ?? {};
  const [selected, setSelected] = useState<IMarketplaceProduct[]>(
    collection?.items ?? []
  );

  const [form] = Form.useForm<IProductCollection>();
  useEffect(() => {
    if (open) {
      if (collection) {
        form.setFieldsValue(collection);
        setSelected(collection.items ?? []);
      } else {
        form.resetFields();
        setSelected([]);
      }
    }
  }, [form, open, collection]);

  useAutoScrollTop("collection-items-selected", [open]);

  const { onPromise, loading } = useOnPromise(async () => {
    const data = await form.validateFields();

    if (selected.length < 4) {
      message.error("Please select at least 4 items");
      return;
    }

    if (collection) {
      await CollectionGateway.updateCollection(
        {
          ...data,
          id: collection.id,
        },
        selected.map((item) => item.id),
        selected[0].photoURLs
      );
    } else {
      await CollectionGateway.createCollection(
        data,
        selected.map((item) => item.id),
        selected[0].photoURLs
      );
    }
    reload?.();
    onClose();
  });
  const isMobile = useMobile();

  return (
    <Modal
      open={open}
      width={isMobile ? "100%" : "80%"}
      cancelText="Cancel"
      okText="Save"
      onCancel={(e) => {
        if (
          (e.target as any).className === "ant-modal-wrap" ||
          (e as any).key === "Escape"
        ) {
          const currentValue = form.getFieldsValue();

          if (collection) {
            const originalValue = pick(collection, Object.keys(currentValue));
            if (
              !isEqual(currentValue, originalValue) ||
              !isEqual(collection.items, selected)
            ) {
              Modal.confirm({
                title: "Are you sure you want to close without saving changes",
                onOk: onClose,
              });
              return;
            }
          } else if (
            Object.keys(pickBy(currentValue)).length > 0 ||
            selected.length > 0
          ) {
            Modal.confirm({
              title: "Are you sure you want to close without saving changes",
              onOk: onClose,
            });
            return;
          }
        }
        onClose();
      }}
      onOk={onPromise}
      confirmLoading={loading}
      title={title}
    >
      <Form form={form}>
        <Title />
        <Description />
        <p className="text-h3">Add items to collection</p>
        <ListItems selected={selected} onChange={setSelected} />
      </Form>
    </Modal>
  );
};

export const AddCollectionButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AutoFloatButton
        type="primary"
        onClick={() => setOpen(true)}
        description="Add new collection"
      />
      <EditCollection open={open} onClose={() => setOpen(false)} />
    </>
  );
};
