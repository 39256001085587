import { get, post } from ".";
import { IShop, IShopProperty, Product, Shop, Show } from "../types";
import {
  failureServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export const ShopGateway = {
  getProducts: async (shopId: number): Promise<IServiceResponse<Product[]>> => {
    try {
      const res = await post<IServiceResponse<Product[]>>(
        "/shop/fetch_products",
        { id: shopId }
      );
      return res;
    } catch (err) {
      return failureServiceResponse("Could not get Products from Shop");
    }
  },
  getShows: async (shopId: number): Promise<IServiceResponse<Show[]>> => {
    try {
      const res = await post<IServiceResponse<Show[]>>("/shop/fetch_shows", {
        id: shopId,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not get Shows from Shop");
    }
  },
  getShopsByQuery: async (query: string): Promise<IServiceResponse<Shop[]>> => {
    try {
      const res = await post<IServiceResponse<Shop[]>>("/shop/query", {
        query,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not find Shops");
    }
  },
  updateShop: async (
    id: number,
    properties: IShopProperty[]
  ): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>("/shop/update", {
        id,
        properties,
      });
    } catch (err) {
      return failureServiceResponse("could not update product");
    }
  },

  createShop: async (
    name: string,
    description: string,
    email: string,
    photoURL: string,
    shippingRate: number
  ): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>("/shop/create", {
        email,
        name,
        photoURL,
        description,
        shippingRate,
      });
    } catch (err) {
      return failureServiceResponse("could not update product");
    }
  },

  associateUser: async (
    shopId: number,
    userId: string
  ): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>("/shop/associate_user", {
        shopId,
        userId,
      });
    } catch (err) {
      return failureServiceResponse("could not update product");
    }
  },

  disassociateUser: async (
    shopId: number,
    userId: string
  ): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>("/shop/disassociate_user", {
        shopId,
        userId,
      });
    } catch (err) {
      return failureServiceResponse("could not update product");
    }
  },
  delete: async (id: number): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>("/shop/delete", {
        id,
      });
    } catch (err) {
      return failureServiceResponse("could not update product");
    }
  },
  getShops: async (name?: string): Promise<IServiceResponse<IShop[]>> => {
    try {
      return await get<IServiceResponse<IShop[]>>("/admin/sellers", {
        take: 200,
        skip: 0,
        name,
        deleted: false,
      });
    } catch (err) {
      return failureServiceResponse("could not get sellers");
    }
  },
  getShop: async (id: string): Promise<IServiceResponse<IShop>> => {
    try {
      return await get<IServiceResponse<IShop>>(`/shop/${id}`);
    } catch (err) {
      return failureServiceResponse("could not get shop id:" + id);
    }
  },
};
