import { Breadcrumb, Row, Tabs } from "antd";
import { useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ShopGateway } from "../../api/ShopGateway";
import { Loading } from "../../components/Loading";
import { usePromise } from "../../utils/usePromise";
import { AddProductButton } from "../inventory/components/AddProduct";
import { AddDropButton } from "./components/AddDrop";
import { DropList } from "./components/DropList";
import { SoldList } from "./components/SoldList";

type Props = {};

export const SellerItems = (props: Props) => {
  const params = useParams<{ sellerId: string }>();
  const { state } = useLocation();

  const items = useMemo(() => {
    const items = [
      {
        key: "unsold items",
        label: "Unsold items",
        children: <SoldList unsold shopId={params.sellerId!} />,
      },
      {
        key: "sold items",
        label: "Sold items",
        children: <SoldList shopId={params.sellerId!} />,
      },
    ];
    if (params.sellerId != null) {
      items.push({
        key: "drops",
        label: "Drops",
        children: <DropList shopId={params.sellerId} />,
      });
    }
    return items;
  }, [params.sellerId]);

  const [key, setKey] = useState(items[0].key);

  const { data: name, loading } = usePromise(async () => {
    if (state) {
      return state.name;
    } else {
      const data = await ShopGateway.getShop(params.sellerId!);
      return data.payload?.name;
    }
  }, [state]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="page-container">
      <Row justify="space-between" align="middle">
        <Breadcrumb className="md:text-h2">
          <Breadcrumb.Item>
            <Link to="/" className="md:!h-9">
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="../" className="md:!h-9">
              Seller Manager
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {name}'s {key} ({params.sellerId})
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row justify="end">
          {key === items[2].key ? (
            <AddDropButton shopId={params.sellerId!} />
          ) : (
            <AddProductButton seller={{ id: params.sellerId!, name }} />
          )}
        </Row>
      </Row>
      <Tabs
        activeKey={key}
        onChange={setKey}
        items={items}
        destroyInactiveTabPane
      />
    </div>
  );
};
