import { Avatar, Breadcrumb, List, Row, Space, Typography } from "antd";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { ShopGateway } from "../../api/ShopGateway";
import { useReloadContext } from "../../components/Providers/ReloadProvider";
import { IShop } from "../../types";
import { usePromise } from "../../utils/usePromise";
import { AddSellerButton } from "./components/AddSeller";
import { SellerItems } from "./SellerItems";

type Props = {};

const Seller = (props: { data: IShop }) => {
  const { data } = props;
  const navigate = useNavigate();
  return (
    <Row
      justify="space-between"
      align="middle"
      className="border border-grey-10 rounded-lg my-1 py-4 pl-8 pr-4 max-md:!p-4 bg-white hover:bg-[#f5f5f5] cursor-pointer"
      onClick={() => navigate(`${data.id}/?shopId=${data.id}`, { state: data })}
    >
      <Space size={20}>
        <Avatar shape="square" size={85} src={data.photoURL} />
        <Typography.Paragraph className="text-h3 !mb-0">
          {data.name}
        </Typography.Paragraph>
      </Space>
    </Row>
  );
};

const ListView = (props: Props) => {
  const { data, reload, loading } = usePromise(ShopGateway.getShops);
  useReloadContext(reload);

  return (
    <div className="page-container">
      <Row justify="space-between" align="middle">
        <Breadcrumb className="md:text-h2">
          <Breadcrumb.Item>
            <Link to="/" className="md:!h-9">
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Seller Manager</Breadcrumb.Item>
        </Breadcrumb>
        <AddSellerButton />
      </Row>
      <List
        loading={loading}
        dataSource={data?.payload ?? []}
        renderItem={(item) => <Seller data={item} />}
        rowKey="id"
      />
    </div>
  );
};

export const SellerManager = (props: Props) => {
  return (
    <Routes>
      <Route path=":sellerId" element={<SellerItems />} />
      <Route path="/" element={<ListView />} />
    </Routes>
  );
};
