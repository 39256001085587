export const tag2Values = [
  "Grunge",
  "Alternative Rock",
  "Psychedelic Rock",
  "Metal",
  "Rock",
  "Festivals",
  "Punk Rock",
  "Soft Rock",
  "Electronic",
  "Movies",
  "Disney Parks",
  "Characters",
  "Comics",
  "Cartoons/Animation",
  "Hip-Hop",
  "R&B/Soul",
  "Art Tees",
  "Comedy/Adventure",
  "Drama/Crime",
  "Actors",
  "Quentin Tarantino",
  "Directors",
  "Action",
  "Sci-Fi",
  "Jim Carrey",
  "Horror/Thriller",
  "Fantasy/Romance",
  "All Sports",
  "Basketball",
  "Baseball",
  "Boxing",
  "Golf",
  "Tennis",
  "Football",
  "NASCAR",
  "Collector Favorites",
  "TV Series",
  "Cartoons/Animations",
  "Video Games",
  "Tech",
];

export const categoryValues = [
  "Band",
  "Disney",
  "Marvel",
  "Anime",
  "Hip-Hop R&B Soul", // Cleaned
  "Art",
  "Movie",
  "Wrestling",
  "Sports",
  "Misc.",
];

export const csvFirstRow = [
  "id",
  "scraped name",
  "year from name",
  "tag1",
  "category1",
  "tshirt",
  "final name",
  "description",
  "price",
  "size from DB",
  "size from name",
  "width",
  "height",
  "image",
  "source",
];
