import { FilterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Radio, Row, Select, Space } from "antd";
import { isNil, omitBy } from "lodash";
import { useEffect, useState } from "react";
import { ShopGateway } from "../../../api/ShopGateway";
import { useURLSearchParams } from "../../../components/URLSearchParamsProvider";
import { SellerPicker } from "./formItems/SellerInput";

type Props = {};

interface Filter {
  editOn: boolean;
  isEdited: boolean;
  isDeleted: boolean;
  availableOn: boolean;
  isAvailable: boolean;
  isAuthenticated: boolean;
  isDiscounted: boolean;
  authEmailOn: boolean;
  authReviewer?: string;
  lastEditorOn: boolean;
  lastEditor?: string;
  sellerOn: boolean;
  shopId?: string;
  priceFrom?: string;
  priceTo?: string;
}

export const InventoryFilter = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<Filter>();
  const {
    setUrlParam,
    deleteUrlParam,
    deleteUrlParamAndReplaceUrl,
    resetUrlParam,
    replaceUrl,
    getUrlParam,
  } = useURLSearchParams();

  const [sellerName, setSellerName] = useState<string>();

  const isEdited =
    getUrlParam("isEdited") === "true"
      ? true
      : getUrlParam("isEdited") === "false"
      ? false
      : undefined;
  const isAvailable =
    getUrlParam("isAvailable") === "true"
      ? true
      : getUrlParam("isAvailable") === "false"
      ? false
      : undefined;
  const isDeleted = getUrlParam("isDeleted") === "true" ? true : undefined;
  const isDiscounted =
    getUrlParam("isDiscounted") === "true" ? true : undefined;
  const isAuthenticated =
    getUrlParam("isAuthenticated") === "true" ? true : undefined;
  const authReviewer = getUrlParam("authReviewer");
  const shopId = getUrlParam("shopId");
  const lastEditor = getUrlParam("lastEditor");
  const priceFrom = getUrlParam("priceFrom");
  const priceTo = getUrlParam("priceTo");

  useEffect(() => {
    open &&
      form.setFieldsValue({
        editOn: isEdited != null,
        isEdited,
        availableOn: isAvailable != null,
        isAvailable,
        isDeleted,
        isDiscounted,
        isAuthenticated,
        authEmailOn: authReviewer != null && authReviewer !== "",
        authReviewer,
        sellerOn: shopId != null && shopId !== "",
        shopId,
        lastEditorOn: lastEditor != null && lastEditor !== "",
        lastEditor,
        priceFrom,
        priceTo,
      });
  }, [
    open,
    authReviewer,
    lastEditor,
    form,
    isAuthenticated,
    isDeleted,
    isAvailable,
    isDiscounted,
    isEdited,
    shopId,
    priceFrom,
    priceTo,
  ]);

  const onFinish = (values: Filter) => {
    deleteUrlParam("page");

    if (values.editOn) {
      setUrlParam("isEdited", String(values.isEdited));
    } else {
      deleteUrlParam("isEdited");
    }

    if (values.availableOn) {
      setUrlParam("isAvailable" as any, String(values.isAvailable));
    } else {
      deleteUrlParam("isAvailable" as any);
    }

    if (values.isDeleted) {
      setUrlParam("isDeleted" as any, String(values.isDeleted));
    } else {
      deleteUrlParam("isDeleted" as any);
    }

    if (values.isAuthenticated) {
      setUrlParam("isAuthenticated" as any, String(values.isAuthenticated));
    } else {
      deleteUrlParam("isAuthenticated" as any);
    }

    if (values.isDiscounted) {
      setUrlParam("isDiscounted" as any, String(values.isDiscounted));
    } else {
      deleteUrlParam("isDiscounted" as any);
    }

    if (values.authEmailOn && values.authReviewer !== "") {
      setUrlParam("authReviewer" as any, String(values.authReviewer));
    } else {
      deleteUrlParam("authReviewer" as any);
    }

    if (values.lastEditorOn && values.lastEditor !== "") {
      setUrlParam("lastEditor" as any, String(values.lastEditor));
    } else {
      deleteUrlParam("lastEditor" as any);
    }

    if (values.sellerOn && values.shopId !== "") {
      setUrlParam("shopId" as any, String(values.shopId));
    } else {
      deleteUrlParam("shopId" as any);
    }

    if (values.priceFrom) {
      setUrlParam("priceFrom", String(values.priceFrom));
    } else {
      deleteUrlParam("priceFrom" as any);
    }

    if (values.priceTo) {
      setUrlParam("priceTo", String(values.priceTo));
    } else {
      deleteUrlParam("priceTo" as any);
    }

    replaceUrl();
    setOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const filters = omitBy(
    {
      isAuthenticated,
      isDeleted,
      isAvailable,
      isDiscounted,
      isEdited,
      authReviewer,
      shopId,
      lastEditor,
      priceFrom,
      priceTo,
    },
    isNil
  );

  const display = Object.keys(filters)
    .map((key) => {
      const value = filters[key as keyof typeof filters];
      if (key === "isEdited") {
        return value ? "edited" : "not edited";
      } else if (key === "isAvailable") {
        return value ? "not sold items" : "sold items";
      } else if (value === true) {
        return key;
      } else if (key === "shopId") {
        return sellerName != null ? `sellerName:${sellerName}` : "";
      } else {
        return `${key}:${value}`;
      }
    })
    .filter((item) => item !== "");

  useEffect(() => {
    (async () => {
      try {
        if (shopId != null) {
          const shops = await ShopGateway.getShops();
          const shop = (shops.payload ?? []).find((item) => item.id === shopId);
          setSellerName(shop?.name);
        }
      } catch (e) {}
    })();
  }, [shopId]);

  return (
    <Select
      placeholder={
        <Row align="middle">
          <FilterOutlined className="mr-1" />
          Filter
        </Row>
      }
      options={[]}
      className="w-[140px] md:w-[295px]"
      dropdownMatchSelectWidth={295}
      mode="tags"
      onDeselect={(key) =>
        ["edited", "not edited"].includes(key)
          ? deleteUrlParamAndReplaceUrl("isEdited")
          : ["sold items", "not sold items"].includes(key)
          ? deleteUrlParamAndReplaceUrl("isAvailable")
          : deleteUrlParamAndReplaceUrl(key.split(":")[0] as any)
      }
      onClear={() => {
        resetUrlParam();
        replaceUrl();
      }}
      value={display}
      open={open}
      dropdownRender={() => (
        <Form
          form={form}
          className="w-full"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={(value) => {
            const {
              authReviewer,
              lastEditor,
              sellerName,
              isEdited,
              editOn,
              isAvailable,
              availableOn,
            } = value;

            if (editOn) {
              form.setFieldValue("isEdited", true);
            } else if (editOn === false) {
              form.setFieldValue("isEdited", undefined);
            }

            if (isEdited != null) {
              form.setFieldValue("editOn", true);
            }
            if (availableOn) {
              form.setFieldValue("isAvailable", false);
            } else if (availableOn === false) {
              form.setFieldValue("isAvailable", undefined);
            }

            if (isAvailable != null) {
              form.setFieldValue("availableOn", true);
            }
            if (authReviewer != null) {
              form.setFieldValue("authEmailOn", authReviewer !== "");
            }
            if (lastEditor != null) {
              form.setFieldValue("lastEditorOn", lastEditor !== "");
            }
            if (sellerName != null) {
              form.setFieldValue("sellerOn", sellerName !== "");
            }
          }}
        >
          <Space direction="vertical" className="w-full" size={0}>
            <Form.Item name="editOn" valuePropName="checked" noStyle>
              <Checkbox className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2">
                <Form.Item name="isEdited" noStyle>
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    size="middle"
                  >
                    <Radio.Button value={true}>Edited</Radio.Button>
                    <Radio.Button value={false}>Not edited</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Checkbox>
            </Form.Item>

            <Form.Item name="availableOn" valuePropName="checked" noStyle>
              <Checkbox className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2">
                <Form.Item name="isAvailable" noStyle>
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    size="middle"
                  >
                    <Radio.Button value={false}>Sold items</Radio.Button>
                    <Radio.Button value={true}>Not sold items</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Checkbox>
            </Form.Item>
            <Form.Item name="isDeleted" valuePropName="checked" noStyle>
              <Checkbox className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2">
                Soft deleted
              </Checkbox>
            </Form.Item>
            <Form.Item name="isAuthenticated" valuePropName="checked" noStyle>
              <Checkbox className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2">
                Authenticated
              </Checkbox>
            </Form.Item>
            <Form.Item name="isDiscounted" valuePropName="checked" noStyle>
              <Checkbox className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2">
                Discounted
              </Checkbox>
            </Form.Item>
            <div className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2">
              <Form.Item name="authEmailOn" valuePropName="checked" noStyle>
                <Checkbox className="w-full">Authenticator email</Checkbox>
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="authReviewer"
                    rules={[
                      {
                        required: getFieldValue("authEmailOn"),
                        type: "email",
                        message: "Please input authenticator email",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      className="my-1"
                      placeholder="Enter email"
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </div>
            <div className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2">
              <Form.Item name="lastEditorOn" valuePropName="checked" noStyle>
                <Checkbox className="w-full">Editor email</Checkbox>
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="lastEditor"
                    rules={[
                      {
                        required: getFieldValue("lastEditorOn"),
                        type: "email",
                        message: "Please input editor email",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      className="my-1"
                      placeholder="Enter email"
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </div>
            <div className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2">
              <Form.Item name="sellerOn" valuePropName="checked" noStyle>
                <Checkbox className="w-full">Seller name</Checkbox>
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => (
                  <SellerPicker
                    selectClassName="my-1"
                    placeholder="Enter name"
                    required={getFieldValue("sellerOn")}
                  />
                )}
              </Form.Item>
            </div>

            <div className="hover:bg-[#f5f5f5] rounded w-full p-1 px-2 columns-2">
              <p>Price range from</p>

              <Form.Item noStyle>
                <Form.Item name="priceFrom">
                  <Input type="number" className="my-2" placeholder="From" />
                </Form.Item>
              </Form.Item>
              <p>to</p>
              <Form.Item noStyle>
                <Form.Item name="priceTo">
                  <Input type="number" className="my-2" placeholder="To" />
                </Form.Item>
              </Form.Item>
            </div>
            <Space>
              <Form.Item noStyle>
                <Button type="primary" className="m-2" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
              <Button
                className="m-2"
                onClick={(e) => {
                  setOpen(false);
                  e.stopPropagation();
                }}
              >
                Close
              </Button>
            </Space>
          </Space>
        </Form>
      )}
      allowClear
      onClick={() => setOpen(true)}
      onKeyDown={(e) => e.key === "Escape" && setOpen(false)}
    />
  );
};
