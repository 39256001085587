import { Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { ShopGateway } from "../../../../api/ShopGateway";
import { useOnPromise } from "../../../../utils/usePromise";

const SellerOptions = [
  { label: "Seller (First Party)", value: "FIRST_PARTY" },
  { label: "Other (Third Party)", value: "THIRD_PARTY" },
];

export const SellerPicker = (props: {
  name?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  selectClassName?: string;
  allowClear?: boolean;
}) => {
  const {
    name = "shopId",
    className,
    placeholder,
    required,
    selectClassName,
    allowClear,
  } = props;
  const { onPromise, loading } = useOnPromise(async (name?: string) => {
    setOptions([]);
    const data = await ShopGateway.getShops(name);
    setOptions(
      (data?.payload ?? []).map((item) => ({
        label: item.name,
        value: item.id,
      }))
    );
  });
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  useEffect(() => {
    onPromise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item
      name={name}
      className={className}
      rules={[
        {
          required,
          message: "Please input name of seller",
        },
      ]}
    >
      <Select
        placeholder={placeholder}
        loading={loading}
        showSearch
        options={options}
        notFoundContent={null}
        className={selectClassName}
        onSearch={onPromise}
        allowClear={allowClear}
        onClear={onPromise}
      />
    </Form.Item>
  );
};

export const SellerInput = () => {
  return (
    <>
      <p className="text-h3 mt-12">Seller</p>
      <Space wrap>
        <Form.Item
          name="type"
          className="w-[295px]"
          rules={[{ required: true, message: "Please input sold by" }]}
        >
          <Select placeholder="Sold by" options={SellerOptions} />
        </Form.Item>
        <Form.Item className="w-[295px]" shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const type = getFieldValue("type");
            return type === "THIRD_PARTY" ? (
              <Form.Item
                name="source"
                className="w-[295px]"
                rules={[
                  {
                    required: true,
                    message: "Please input website URL",
                  },
                ]}
              >
                <Input placeholder="Enter website URL" />
              </Form.Item>
            ) : (
              <SellerPicker
                className="w-[295px]"
                placeholder="Enter name of seller"
                allowClear
                required
              />
            );
          }}
        </Form.Item>
      </Space>
    </>
  );
};
