import { Button, InputNumber, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { CreditGateway } from "../../../api/CreditGateway";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { useOnPromise } from "../../../utils/usePromise";

type Props = {
  userId: string;
};

const AddCredit = (props: {
  open: boolean;
  onClose: () => void;
  userId: string;
}) => {
  const { open, onClose, userId } = props;
  const [value, setValue] = useState<number | null>();

  const { reload } = useReloadContext() ?? {};

  const { onPromise, loading } = useOnPromise(async () => {
    const currentValue = value ?? 0;
    if (currentValue > 0) {
      try {
        await CreditGateway.addCreditToUser(userId, currentValue);
        onClose();
        reload?.();
      } catch (error: any) {
        message.error(error?.message ?? "Failed");
      }
    }
  });

  useEffect(() => {
    open && setValue(null);
  }, [open]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title="Add credit"
      onOk={onPromise}
      okButtonProps={{ loading }}
      destroyOnClose
    >
      <InputNumber
        min={0}
        precision={2}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        value={value}
        onChange={setValue}
        addonBefore="$"
      />
    </Modal>
  );
};

export const AddCreditButton = (props: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button type="link" onClick={() => setOpen(true)}>
        Add credit
      </Button>
      <AddCredit
        userId={props.userId}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
