import { DeleteFilled } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Popconfirm,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ISOGateway } from "../../api/ISOGateway";
import { useReloadContext } from "../../components/Providers/ReloadProvider";
import { User } from "../../types";
import { IISO } from "../../types/IISO";
import { formatDate } from "../../utils/time";
import { useOnPromise, usePromise } from "../../utils/usePromise";

type Props = {};

const Fulfilled = (props: { id: string; fulfilled: boolean }) => {
  const { id } = props;
  const [fulfilled, setfulfilled] = useState(props.fulfilled);
  const { onPromise, loading } = useOnPromise(async () => {
    const newValue = !fulfilled;
    await ISOGateway.updateISO(id, { fulfilled: newValue });
    setfulfilled(newValue);
  });
  return (
    <Spin spinning={loading}>
      <Checkbox checked={fulfilled} onClick={onPromise} />
    </Spin>
  );
};

const DeleteButton = (props: { id: string }) => {
  const { reload } = useReloadContext() ?? {};
  const { id } = props;
  const { onPromise } = useOnPromise(async () => {
    await ISOGateway.updateISO(id, { deleted: true });
    reload?.();
  });
  return (
    <Popconfirm
      title="Are you sure to delete this item?"
      icon={null}
      okButtonProps={{ danger: true }}
      onConfirm={onPromise}
    >
      <Button type="link" danger>
        Delete
        <DeleteFilled />
      </Button>
    </Popconfirm>
  );
};

const columns = [
  {
    title: "Title",
    key: "title",
    render: (data: IISO) => {
      return (
        <a href={data.sourcedAt} className="text-blue">
          {data.title}
        </a>
      );
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (description: string) => {
      return (
        <Typography.Paragraph ellipsis={{ rows: 4 }} className="!mb-0">
          {description}
        </Typography.Paragraph>
      );
    },
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    render: (user: User) => {
      return user.username ? `@${user.username}` : "user not found";
    },
  },
  {
    title: "Email",
    dataIndex: "user",
    key: "email",
    render: (user: User) => {
      return user.email ?? "email not found";
    },
  },
  {
    title: "Date requested",
    dataIndex: "createdAt",
    key: "date",
    render: (createdAt: string) => {
      return formatDate(new Date(createdAt));
    },
  },
  {
    title: "Fulfilled",
    key: "fulfilled",
    render: (data: IISO) => {
      return <Fulfilled id={data.id} fulfilled={data.fulfilled} />;
    },
  },
  {
    title: "",
    key: "action",
    fixed: "right" as const,
    render: ({ id }: { id: string }) => <DeleteButton id={id} />,
    width: 100,
  },
];

export const ISOInventory = (props: Props) => {
  const { data, reload, loading } = usePromise(ISOGateway.getISOs);
  useReloadContext(reload);

  return (
    <div className="page-container">
      <Row justify="space-between" align="middle">
        <Breadcrumb className="md:text-h2">
          <Breadcrumb.Item>
            <Link to="/" className="md:!h-9">
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>ISO inventory</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey="id"
        scroll={{ x: 800 }}
        className="mt-4"
      />
    </div>
  );
};
