import { del, get, post, put } from ".";
import { Discount } from "../types";
import {
  failureServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export const DiscountGateway = {
  getDiscounts: async (): Promise<Discount[]> => {
    try {
      const res = await get<IServiceResponse<Discount[]>>("/discounts");
      return res.payload || [];
    } catch (error) {
      throw new Error("could not get discounts");
    }
  },

  getDiscountById: async (discountId: string): Promise<Discount | null> => {
    try {
      const res = await get<IServiceResponse<Discount>>(
        `/discounts/${discountId}`
      );
      return res.payload;
    } catch (error) {
      throw new Error("could not get discount by id");
    }
  },

  createDiscount: async (params: {
    type: string;
    amount: number;
    resourceId: string;
    startsAt: Date;
    expiresAt: Date;
    resourceType: string;
  }): Promise<IServiceResponse<Discount>> => {
    try {
      const res = await post<IServiceResponse<Discount>>("/discounts", params);
      return res;
    } catch (error) {
      return failureServiceResponse("could not create discount");
    }
  },

  updateDiscount: async (
    discountId: string,
    params: {
      amount?: number;
      expiresAt?: Date;
    }
  ): Promise<IServiceResponse<Discount>> => {
    try {
      const res = await put<IServiceResponse<Discount>>(
        `/discounts/${discountId}`,
        params
      );
      return res;
    } catch (error) {
      return failureServiceResponse("could not update discount");
    }
  },

  deleteDiscount: async (
    discountId: string
  ): Promise<IServiceResponse<Discount>> => {
    try {
      const res = await del<IServiceResponse<Discount>>(
        `/discounts/${discountId}`,
        {}
      );
      return res;
    } catch (error) {
      return failureServiceResponse("could not update discount");
    }
  },
};
