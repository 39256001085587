import { Form } from "antd";
import { DraggableUpload } from "../../../../components/DraggableUpload";
import { UploadButton } from "../../../../components/FormInput/PhotosInput";
import { uploadFileProperties } from "../../../../utils/file";

export const PhotosInput = (props: { maxCount?: number }) => {
  const { maxCount = 5 } = props;
  return (
    <>
      <p className="text-h3 mt-6 mb-3">Photos (Max {maxCount})</p>
      <Form.Item
        noStyle
        shouldUpdate={(pre, next) =>
          (pre?.photoURLs ?? []).length !== (next?.photoURLs ?? []).length
        }
      >
        {({ getFieldValue }) => (
          <Form.Item
            name="photoURLs"
            rules={[
              {
                required: true,
                message: "Please upload photos",
              },
            ]}
          >
            <DraggableUpload
              listType="picture-card"
              {...uploadFileProperties({
                maxCount,
                currentCount: (getFieldValue("photoURLs") ?? []).length,
              })}
            >
              {(getFieldValue("photoURLs") ?? []).length < maxCount ? (
                <UploadButton />
              ) : null}
            </DraggableUpload>
          </Form.Item>
        )}
      </Form.Item>
    </>
  );
};
