import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { NotificationsGateway } from "../../api/NotificationsGateway";

const NotificationsModal = ({
  isNotificationsModalOpen,
  onNotificationsModalClose,
}: {
  isNotificationsModalOpen: boolean;
  onNotificationsModalClose: () => void;
}) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [userId, setUserId] = useState("");
  const [sendToAll, setSendToAll] = useState("NO");
  const [targetApp, setTargetApp] = useState("A");
  const [targetAudience, setTargetAudience] = useState("ALL");
  const [dateValue, setDate] = useState(new Date());

  const handleSubmit = async () => {
    if (!title || !message) {
      alert("Missing notification content");
      return;
    }

    let selected = 0;
    selected = deviceId ? selected + 1 : selected;
    selected = userId ? selected + 1 : selected;
    selected = sendToAll === "YES" ? selected + 1 : selected;

    if (selected === 0) {
      alert("Missing selection");
      return;
    }

    if (selected > 1) {
      alert("Selected more than 1");
      return;
    }

    let response;
    if (deviceId) {
      response = await NotificationsGateway.sendNotificationToDevice(
        title,
        message,
        deviceId,
        targetApp
      );
    } else if (userId) {
      response = await NotificationsGateway.sendNotificationToUser(
        title,
        message,
        userId,
        targetApp
      );
    } else {
      response = await NotificationsGateway.sendNotificationToAll(
        title,
        message,
        targetApp,
        targetAudience
      );
    }

    if (!response.success) {
      alert("Notification failed!");
      return;
    }

    alert("Notification sent!");
  };

  const handleScheduleNotif = async () => {
    const response = await NotificationsGateway.scheduleNotif(dateValue);

    if (!response.success) {
      alert("Notification failed!");
      return;
    }

    alert(`Notification scheduled for: ${dateValue}`);
  };

  return (
    <Modal
      isOpen={isNotificationsModalOpen}
      onClose={onNotificationsModalClose}
      size="full"
    >
      <ModalOverlay />
      <ModalContent width="90%" margin="10" height={"90%"} overflow={"scroll"}>
        <ModalHeader>Send Notification</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Container maxWidth={"50%"} marginInline={0}>
            <VStack alignItems={"stretch"}>
              <HStack>
                <Box w={"35%"}>Message Title</Box>
                <Input
                  placeholder="Notification title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </HStack>
              <HStack>
                <Box w={"35%"}>Message Content</Box>
                <Input
                  placeholder="Notification content"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </HStack>

              <hr></hr>

              <Heading as="h5" size="sm">
                Select one of the following:
              </Heading>

              <HStack>
                <Box w={"35%"}>Device ID</Box>
                <Input
                  placeholder="Device ID"
                  value={deviceId}
                  onChange={(e) => {
                    setDeviceId(e.target.value);
                  }}
                />
              </HStack>

              <HStack>
                <Box w={"35%"}>User Id</Box>
                <Input
                  placeholder="User ID"
                  value={userId}
                  onChange={(e) => {
                    setUserId(e.target.value);
                  }}
                />
              </HStack>

              <HStack>
                <Box w={"30%"}>All devices</Box>
                <RadioGroup onChange={setSendToAll} value={sendToAll}>
                  <Stack direction="row">
                    <Radio value="NO">NO</Radio>
                    <Radio value="YES">YES</Radio>
                  </Stack>
                </RadioGroup>
              </HStack>

              <hr></hr>

              <HStack>
                <Box w={"30%"}>Target App</Box>
                <RadioGroup onChange={setTargetApp} value={targetApp}>
                  <Stack direction="row">
                    <Radio value="A" defaultChecked>
                      Sellout App
                    </Radio>
                    <Radio value="M">Marketplace App</Radio>
                  </Stack>
                </RadioGroup>
              </HStack>

              <HStack>
                <Box w={"30%"}>Sellout Audience</Box>
                <RadioGroup onChange={setTargetAudience} value={targetAudience}>
                  <Stack direction="row">
                    <Radio value="ALL_USERS" defaultChecked>
                      All Users
                    </Radio>
                    <Radio value="FREE_USERS">Free Users</Radio>
                  </Stack>
                </RadioGroup>
              </HStack>

              <hr></hr>
              <Button
                w={"20%"}
                fontSize={"sm"}
                bg={"blue.400"}
                color={"white"}
                boxShadow={
                  "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                }
                _hover={{
                  bg: "blue.500",
                }}
                _focus={{
                  bg: "blue.500",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </VStack>
          </Container>

          <Container maxWidth={"50%"} marginInline={0} paddingTop={"5%"}>
            <VStack alignItems={"stretch"}>
              <hr></hr>

              <Heading as="h2" size="sm">
                Select date for credits notificaiton top up:
              </Heading>
              <HStack>
                <Box w={"35%"}>Date</Box>
                <DateTimePicker onChange={setDate} value={dateValue} />
              </HStack>

              <hr></hr>
              <Button
                w={"30%"}
                fontSize={"sm"}
                bg={"blue.400"}
                color={"white"}
                boxShadow={
                  "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                }
                _hover={{
                  bg: "blue.500",
                }}
                _focus={{
                  bg: "blue.500",
                }}
                onClick={handleScheduleNotif}
              >
                Schedule Top Up Notif
              </Button>
            </VStack>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onNotificationsModalClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NotificationsModal;
