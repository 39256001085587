import { isNil, omitBy } from "lodash";
import { del, get, post, put } from ".";
import { IMarketplaceProduct } from "../types";
import {
  IPaginationServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";
import { ICreateMarketplaceProduct } from "./MarketplaceGateway.type";

export const MarketplaceGateway = {
  getProducts: async (params?: {
    tag?: string;
    keyword?: string;
    page?: number;
    pageSize?: number;
    sort?: string;
    isDiscounted?: boolean;
    isEdited?: boolean;
    shopId?: string;
    authReviewer?: string;
    productId?: string;
    keywords?: string;
    authStatus?: string;
    isDeleted?: boolean;
    tags?: string;
    lastEditor?: string;
    priceFrom?: string;
    priceTo?: string;
    isAvailable?: boolean;
  }) => {
    const { page = 1, pageSize = 20, ...rest } = params ?? {};
    const skip = (page - 1) * pageSize;
    try {
      const res = await get<
        IPaginationServiceResponse<{ products: IMarketplaceProduct[] }>
      >("/admin/marketplace", { ...omitBy(rest, isNil), skip, size: pageSize });
      return res.payload;
    } catch (err) {
      throw new Error("Could not get Live Markets Products");
    }
  },
  getTags: async (): Promise<{ name: string; id: string }[]> => {
    try {
      const res = await get<IServiceResponse<{ name: string; id: string }[]>>(
        "/admin/marketplace/tags"
      );
      return (res.payload ?? []).filter((item) => item.id !== "flash_sale");
    } catch (err) {
      throw new Error("Could not get product tags");
    }
  },
  deleteProduct: async (productId: string) => {
    try {
      await del(`/admin/product/${productId}`);
    } catch (err) {
      throw new Error("Could not get Live Markets");
    }
  },
  createProduct: async (body: ICreateMarketplaceProduct) => {
    try {
      const res = await post<IServiceResponse<{ name: string; id: string }[]>>(
        "/v2/admin/product",
        { ...body, discountType: "PERCENT" }
      );
      return res.payload ?? [];
    } catch (err) {
      throw new Error("Could not create product");
    }
  },
  updateProduct: async (id: string, body: ICreateMarketplaceProduct) => {
    try {
      const res = await put<IServiceResponse<{ name: string; id: string }[]>>(
        `/admin/product/${id}`,
        { ...body, discountType: "PERCENT" }
      );
      return res.payload ?? [];
    } catch (err) {
      throw new Error("Could not update product");
    }
  },
};

// function _processMarketsPayload(markets: IMarket[]): IMarket[] {
//   return _.map(markets, (market) => {
//     market.startsAt = new Date(market.startsAt);
//     market.endsAt = new Date(market.endsAt);
//     market.createdAt = new Date(market.createdAt);
//     market.updatedAt = new Date(market.updatedAt);
//     return market;
//   });
// }
