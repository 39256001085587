import { Breadcrumb, Row, Tabs } from "antd";
import { Link } from "react-router-dom";
import { AddGiveawayButton } from "./components/AddGiveaway";
import { GiveawayList } from "./components/GiveawayList";

type Props = {};

const items = [
  {
    key: "active",
    label: "Active",
    children: <GiveawayList status="active" />,
  },
  {
    key: "scheduled",
    label: "Scheduled",
    children: <GiveawayList status="scheduled" />,
  },
  {
    key: "completed",
    label: "Completed",
    children: <GiveawayList status="ended" />,
  },
];

export const Giveaways = (props: Props) => {
  return (
    <div className="page-container">
      <Row justify="space-between" align="middle">
        <Breadcrumb className="md:text-h2">
          <Breadcrumb.Item>
            <Link to="/" className="md:!h-9">
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Manage giveaways</Breadcrumb.Item>
        </Breadcrumb>
        <AddGiveawayButton />
      </Row>
      <Tabs items={items} destroyInactiveTabPane />
    </div>
  );
};
