import { del, get, post, put } from ".";
import { IRaffle, RaffleStatus } from "../types/IRaffle";
import {
  failureServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export interface IPhotoUploadPayload {
  photoUrl: string;
  uploadUrl: string;
}

export interface IVideoUploadPayload {
  videoUrl: string;
  uploadUrl: string;
}

export const RaffleGateway = {
  getRaffles: async (
    status?: RaffleStatus,
    limit = 200
  ): Promise<IServiceResponse<IRaffle[]>> => {
    try {
      const response = await get<IServiceResponse<IRaffle[]>>("/raffle", {
        status,
        limit,
      });
      return response;
    } catch (err) {
      return failureServiceResponse("could not get raffles");
    }
  },
  createRaffle: async (
    data: Omit<IRaffle, "id" | "enteredUserCount" | "entriesCount">
  ): Promise<IServiceResponse<IRaffle[]>> => {
    try {
      const response = await post<IServiceResponse<IRaffle[]>>(
        "/admin/raffle",
        data
      );
      return response;
    } catch (err) {
      return failureServiceResponse("could not create raffles");
    }
  },
  updateRaffle: async (
    id: string,
    data: Omit<IRaffle, "id" | "enteredUserCount" | "entriesCount">
  ): Promise<IServiceResponse<IRaffle[]>> => {
    try {
      const response = await put<IServiceResponse<IRaffle[]>>(
        "/admin/raffle/" + id,
        data
      );
      return response;
    } catch (err) {
      return failureServiceResponse("could not update raffle");
    }
  },
  delRaffle: async (id: string): Promise<IServiceResponse<IRaffle[]>> => {
    try {
      const response = await del<IServiceResponse<IRaffle[]>>(
        "/raffle/" + id,
        {}
      );
      return response;
    } catch (err) {
      return failureServiceResponse("could not delete raffle");
    }
  },
  uploadPhoto: async (): Promise<IServiceResponse<IPhotoUploadPayload>> => {
    try {
      const response = await post<IServiceResponse<IPhotoUploadPayload>>(
        "/admin/raffle/image",
        {}
      );
      return response;
    } catch (err) {
      return failureServiceResponse("could not get image upload urls");
    }
  },
  uploadVideo: async (): Promise<IServiceResponse<IVideoUploadPayload>> => {
    try {
      const response = await post<IServiceResponse<IVideoUploadPayload>>(
        "/admin/raffle/video",
        {}
      );
      return response;
    } catch (err) {
      return failureServiceResponse("could not get video upload urls");
    }
  },
  runDrawing: async (id: string): Promise<IServiceResponse<any>> => {
    try {
      const response = await post<IServiceResponse<any>>(
        `/admin/raffle/${id}/draw`,
        {}
      );
      return response;
    } catch (err) {
      return failureServiceResponse("could not run drawing");
    }
  },
};
