import {
  Button,
  Center,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { DiscountGateway } from "../../api/DiscountGateway";
import { Discount } from "../../types";

export const DiscountForm = ({
  discount,
  setRefresh,
  setSelectedDiscount,
  refresh,
}: {
  discount: Discount;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDiscount: React.Dispatch<
    React.SetStateAction<Discount | undefined>
  >;
  refresh: boolean;
}) => {
  console.log("discount", discount);

  // initial state
  const [type, setType] = useState(discount.type);
  const [amount, setAmount] = useState<number | undefined>(discount.amount);
  const [categoryId, setCategoryId] = useState<string>(
    _.isNil(discount.tagTypeId) ? "" : discount.tagTypeId
  );
  const [startsAt, setStartsAt] = useState<Date>(discount.startsAt);
  const [expiresAt, setExpiresAt] = useState<Date>(
    new Date(discount.expiresAt)
  );
  const [discountId, setDiscountId] = useState(discount.id);

  // when the discount changes, trigger updates in the form
  if (discountId !== discount.id) {
    setType(discount.type);
    setAmount(discount.amount);
    setCategoryId(_.isNil(discount.tagTypeId) ? "" : discount.tagTypeId);
    setStartsAt(discount.startsAt);
    setExpiresAt(discount.expiresAt);
    setDiscountId(discount.id);
  }

  const handleSubmit = async () => {
    if (!amount) {
      alert("amount is required for discount creation");
      return;
    }

    if (_.isNil(expiresAt)) {
      alert("need to have an expire time");
      return;
    }

    const res = await DiscountGateway.updateDiscount(discountId, {
      amount,
      expiresAt,
    });

    if (res.success === false) {
      alert(res.message);
    }
    setRefresh((refresh) => !refresh);
  };

  const handleDelete = async () => {
    return;
  };

  return (
    <Center py={6}>
      <Stack
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={1}
        pt={2}
      >
        <Heading as="h3" size="sm" textAlign={"right"}>
          {discount.id}
        </Heading>
        <Input
          placeholder={"Amount"}
          type={"number"}
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value ? _.toNumber(e.target.value) : undefined);
          }}
          size="sm"
        />
        <Input
          placeholder={discount.resourceId || "Tag ID"}
          value={categoryId}
          onChange={(e) => {
            setCategoryId(discount.resourceId || e.target.validationMessage);
          }}
          size="sm"
        />
        <HStack>
          <FormLabel>Expire Date: </FormLabel>
          <DateTimePicker value={expiresAt} onChange={setExpiresAt} />
        </HStack>
        <Stack
          width={"100%"}
          mt={"2rem"}
          direction={"row"}
          padding={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            flex={1}
            fontSize={"sm"}
            bg={"blue.400"}
            color={"white"}
            boxShadow={
              "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
            }
            _hover={{ bg: "blue.500" }}
            _focus={{ bg: "blue.500" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button
            flex={1}
            fontSize={"sm"}
            bg={"red.400"}
            color={"white"}
            boxShadow={
              "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
            }
            _hover={{ bg: "red.500" }}
            _focus={{ bg: "red.500" }}
            onClick={handleDelete}
          >
            Delete Discount
          </Button>
        </Stack>
      </Stack>
    </Center>
  );
};
