import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Box,
  HStack,
  FormLabel,
  Button,
  ModalFooter,
  Spacer,
} from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { get, post } from "../../api";
import { IServiceResponse } from "../../types/IServiceResponse";
import MarketplaceOrderBox from "../MarketplaceOrderBox";
import RaffleBox from "../RaffleBox";

const MarketplaceOrdersModal = ({
  isMarketplaceOrdersModalOpen,
  onMarketplaceOrdersModalClose,
  selectUser, 
}: {
  isMarketplaceOrdersModalOpen: boolean;
  onMarketplaceOrdersModalClose: () => void;
  selectUser: (query: string) => void
}) => {
  const [orders, setOrders] = useState<any[]>()

  const [from, setFrom] = useState<Date>(
    new Date(Date.now() - 1000 * 60 * 60 * 24)
  );
  const [to, setTo] = useState<Date>(
    new Date(Date.now())
  );
  useEffect(() => {
    fetchOrders();
  }, [isMarketplaceOrdersModalOpen]);

  const fetchOrders = () =>
    Promise.all([
      post<IServiceResponse<any>>("/marketplace/orders", { from, to })
      .then((res) => {
        if (res.payload && res.success) {
          setOrders(res.payload)
        }
      }),
    ]).then(() => Promise.resolve())

  return (
    <Modal
      isOpen={isMarketplaceOrdersModalOpen}
      onClose={onMarketplaceOrdersModalClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent width="600px">
        <ModalHeader>Marketplace Orders</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Button onClick={fetchOrders}>fetch</Button>
          <HStack>
            <VStack>
              <FormLabel>FROM: </FormLabel>
              <DateTimePicker value={from} onChange={setFrom} />
            </VStack>
            <VStack>
              <FormLabel>TO: </FormLabel>
              <DateTimePicker value={to} onChange={setTo} />
            </VStack>
          </HStack>
          <Spacer height={5} />
          <VStack>
            {orders &&
              orders.map((order, index) => (
                <MarketplaceOrderBox key={index} order={order} selectUser={selectUser} refetch={fetchOrders}/>
              ))}
          </VStack>
          <Spacer height={5} />
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onMarketplaceOrdersModalClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MarketplaceOrdersModal;
