import { EnvironmentName, EnvironmentURL } from "../config";

export const getEnvironmentName = () => {
  return EnvironmentName ?? "DEVO";
};

export const getEnvironmentURL = () => {
  return EnvironmentURL ?? "localhost";
};

export const isDebuging =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";
