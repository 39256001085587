import { ChevronRightIcon } from "@chakra-ui/icons";
import { Avatar, Button, Empty, Row, Space, Spin } from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { DropGateway } from "../../../api/DropGateway";
import { useReloadContext } from "../../../components/Providers/ReloadProvider";
import { IProductDrop } from "../../../types/IProductDrop";
import { formatDate } from "../../../utils/time";
import { useMobile } from "../../../utils/useMobile";
import { usePromise } from "../../../utils/usePromise";
import { EditDrop } from "./AddDrop";

type Props = {
  shopId: string;
};

const Item = (props: { item: IProductDrop; description: string }) => {
  const { name, photoURLs } = props.item;
  const { description } = props;
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);

  const Images = (
    <Space wrap>
      {photoURLs.map((url, index) => (
        <Avatar key={index} size={82} src={url} shape="square" />
      ))}
    </Space>
  );

  if (isMobile) {
    return (
      <>
        <Space
          direction="vertical"
          className="w-full border-b border-neutral-5 my-1 py-4 bg-white hover:bg-[#f5f5f5] cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <Row justify="space-between" align="top">
            <Space direction="vertical">
              <p className="text-link">{name}</p>
              <p className="text-body">{description}</p>
            </Space>

            <Button type="link" className="!p-0 !h-fit">
              See details
              <ChevronRightIcon />
            </Button>
          </Row>
          {Images}
        </Space>
        <EditDrop
          open={open}
          onClose={() => setOpen(false)}
          drop={props.item}
          shopId={props.item.shopId}
        />
      </>
    );
  } else {
    return (
      <>
        <Row
          justify="space-between"
          align="bottom"
          className="border-b border-neutral-5 my-1 py-4 bg-white hover:bg-[#f5f5f5] cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <Space direction="vertical">
            <p className="text-link">{name}</p>
            <p className="text-body">{description}</p>

            <Button type="link" className="!p-0 !h-fit">
              See details
            </Button>
          </Space>
          {Images}
        </Row>
        <EditDrop
          open={open}
          onClose={() => setOpen(false)}
          drop={props.item}
          shopId={props.item.shopId}
        />
      </>
    );
  }
};

export const DropList = (props: Props) => {
  const {
    data = [],
    reload,
    loading,
  } = usePromise(() => DropGateway.getDrops(props.shopId), [props.shopId]);

  useReloadContext(reload);

  const { active, scheduled, past } = useMemo(() => {
    const active = data.filter(
      (item) =>
        dayjs(item.startsAt).isBefore(dayjs()) &&
        dayjs(item.endsAt).isAfter(dayjs())
    );
    const scheduled = data.filter((item) =>
      dayjs(item.startsAt).isAfter(dayjs())
    );
    const past = data.filter((item) => dayjs(item.endsAt).isBefore(dayjs()));
    return { active, scheduled, past };
  }, [data]);

  return (
    <Spin spinning={loading}>
      <p className="text-h4 my-2">Live now</p>
      {active.length > 0 ? (
        active.map((item) => (
          <Item key={item.id} item={item} description="Active drop" />
        ))
      ) : loading ? null : (
        <Empty />
      )}
      <p className="text-h4 my-2">Scheduled drops</p>
      {scheduled.length > 0 ? (
        scheduled.map((item) => (
          <Item
            key={item.id}
            item={item}
            description={`Scheduled ${formatDate(new Date(item.startsAt))}`}
          />
        ))
      ) : loading ? null : (
        <Empty />
      )}
      <p className="text-h4 my-2">Past drops</p>
      {past.length > 0 ? (
        past.map((item) => (
          <Item
            key={item.id}
            item={item}
            description={`Completed ${formatDate(new Date(item.endsAt))}`}
          />
        ))
      ) : loading ? null : (
        <Empty />
      )}
    </Spin>
  );
};
