import { EllipsisOutlined, SortDescendingOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Checkbox,
  FloatButton,
  List,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { differenceBy, unionBy } from "lodash";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { MarketplaceGateway } from "../../api/MarketplaceGateway";
import {
  ReloadContextProvider,
  useReloadContext,
} from "../../components/Providers/ReloadProvider";
import { useURLSearchParams } from "../../components/URLSearchParamsProvider";
import { IMarketplaceProduct } from "../../types";
import { useMobile } from "../../utils/useMobile";
import { usePromise } from "../../utils/usePromise";
import { useAutoScrollTop } from "../../utils/useScroll";
import { AddProductButton } from "./components/AddProduct";
import { DownloadCSVButton } from "./components/DownloadCSVButton";
import { InventoryFilter } from "./components/InventoryFilter";
import { ProductCard } from "./components/ProductCard";
import { Search } from "./components/Search";

type Props = {
  right?: JSX.Element;
  className?: string;
  selection?: {
    selected: IMarketplaceProduct[];
    onChange: (selected: IMarketplaceProduct[]) => void;
  };
};

const SortOptions = [
  { value: "finalPrice:desc", label: "Price (high to low)" },
  { value: "finalPrice:asc", label: "Price (low to high)" },
  { value: "createdAt:desc", label: "Recently added" },
  { value: "updatedAt:desc", label: "Recent edit" },
  { value: "updatedAt:asc", label: "Oldest edit" },
];

const Table = (props: Props) => {
  const {
    commonUrlParams,
    setUrlParam,
    setUrlParamAndReplaceUrl,
    deleteUrlParamAndReplaceUrl,
    getUrlParam,
  } = useURLSearchParams();
  const { sort, pageSize, page } = commonUrlParams;
  const isEdited =
    getUrlParam("isEdited") === "true"
      ? true
      : getUrlParam("isEdited") === "false"
      ? false
      : undefined;
  const isAvailable =
    getUrlParam("isAvailable") === "true"
      ? true
      : getUrlParam("isAvailable") === "false"
      ? false
      : undefined;
  const isDeleted = getUrlParam("isDeleted") === "true" ? true : undefined;
  const isDiscounted =
    getUrlParam("isDiscounted") === "true" ? true : undefined;
  const isAuthenticated =
    getUrlParam("isAuthenticated") === "true" ? true : undefined;
  const authReviewer = getUrlParam("authReviewer");
  const shopId = getUrlParam("shopId");
  const lastEditor = getUrlParam("lastEditor");
  const productId = getUrlParam("productId");
  const keyword = getUrlParam("keywords");
  const tags = getUrlParam("tags");
  const priceFrom = getUrlParam("priceFrom");
  const priceTo = getUrlParam("priceTo");

  const { data, reload, loading } = usePromise(
    () =>
      MarketplaceGateway.getProducts({
        page,
        pageSize,
        sort,
        isEdited,
        isDiscounted,
        authReviewer,
        shopId,
        productId,
        keyword,
        authStatus: isAuthenticated ? "AUTHENTICATED" : undefined,
        isDeleted,
        tags,
        lastEditor,
        priceFrom,
        priceTo,
        isAvailable,
      }),
    [
      page,
      pageSize,
      sort,
      isEdited,
      isDiscounted,
      authReviewer,
      shopId,
      productId,
      keyword,
      isAuthenticated,
      isDeleted,
      tags,
      lastEditor,
      priceFrom,
      priceTo,
      isAvailable,
    ]
  );
  useReloadContext(reload);

  const dataSource = data?.data.products;

  const checked = useMemo(
    () =>
      props.selection
        ? props.selection.selected.length > 0 &&
          (dataSource ?? []).length > 0 &&
          props.selection.selected.length >= (dataSource ?? []).length &&
          differenceBy(dataSource ?? [], props.selection.selected, "id")
            .length === 0
        : false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource, props.selection?.selected]
  );

  useAutoScrollTop("inventory", [dataSource]);

  const isMobile = useMobile();

  return (
    <div className={`flex flex-col ${props.className ?? ""}`}>
      {props.selection && (
        <Spin spinning={loading}>
          <Checkbox
            checked={checked}
            onChange={(e) =>
              e.target.checked
                ? props.selection?.onChange(
                    unionBy(props.selection.selected, dataSource ?? [], "id")
                  )
                : props.selection?.onChange(
                    differenceBy(
                      props.selection.selected,
                      dataSource ?? [],
                      "id"
                    )
                  )
            }
            className="mt-2"
          >
            Select all
          </Checkbox>
        </Spin>
      )}
      <Row justify="space-between" align="middle" className="mt-6" wrap>
        <Space align="center" wrap>
          <Select
            placeholder={
              <Row align="middle">
                <SortDescendingOutlined className="mr-1" />
                Sort
              </Row>
            }
            options={SortOptions}
            value={sort}
            onChange={(value) =>
              value
                ? setUrlParamAndReplaceUrl("sort", value)
                : deleteUrlParamAndReplaceUrl("sort", true)
            }
            className="w-[177px] md:w-[295px]"
            allowClear
          />

          <InventoryFilter />
        </Space>
        {props.selection ? null : isMobile ? (
          <FloatButton.Group
            trigger="click"
            type="primary"
            icon={<EllipsisOutlined />}
          >
            {props.right}
            <DownloadCSVButton dataSource={dataSource ?? []} float />
          </FloatButton.Group>
        ) : (
          <Space wrap>
            {!props.selection && (
              <DownloadCSVButton dataSource={dataSource ?? []} />
            )}
            {props.right}
          </Space>
        )}
      </Row>
      <List
        id="inventory"
        dataSource={dataSource ?? []}
        grid={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
        }}
        renderItem={(item) => (
          <ProductCard
            item={item}
            checkbox={
              props.selection && {
                checked: props.selection.selected.some(
                  (sel) => sel.id === item.id
                ),
                onChange: (checked, item) => {
                  const { selected, onChange } = props.selection!;
                  const current = checked
                    ? selected.concat(item)
                    : selected.filter((sel) => sel.id !== item.id);
                  onChange(current);
                },
              }
            }
          />
        )}
        loading={loading}
        className="flex-auto overflow-auto mt-4 md:p-4 pr-0"
        rowKey="id"
      />
      {data && data.total > 0 && (
        <Row justify="center" className="mt-4">
          <Pagination
            current={page}
            pageSize={pageSize}
            pageSizeOptions={[20, 50, 100, 250]}
            total={data.total}
            onChange={(page, newPageSize) => {
              setUrlParam("pageSize", newPageSize);
              setUrlParamAndReplaceUrl(
                "page",
                pageSize === newPageSize ? page : 1
              );
            }}
          />
        </Row>
      )}
    </div>
  );
};

export const InventoryTable = (props: Props) => {
  return (
    <ReloadContextProvider>
      <Table {...props} />
    </ReloadContextProvider>
  );
};

export const Inventory = () => {
  return (
    <div className="page-container">
      <Row justify="space-between" align="middle">
        <Breadcrumb className="md:text-h2">
          <Breadcrumb.Item>
            <Link to="/" className="md:!h-9">
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Inventory tool</Breadcrumb.Item>
        </Breadcrumb>
        <Search />
      </Row>
      <InventoryTable
        className="h-full overflow-hidden"
        right={<AddProductButton type="default" />}
      />
    </div>
  );
};
