import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useState } from "react";
import { User } from "../../../types";

type Props = {
  id: string;
  user?: User;
};

export const WinnerInfo = (props: Props) => {
  const { user } = props;
  const [visible, setVisible] = useState(false);

  return visible ? (
    <Space direction="vertical">
      {user && (
        <>
          <p className="text-link">{user?.username ?? ""}</p>
          <p className="text-blue underline">{user.id}</p>
          <p className="text-body">{user?.email ?? ""}</p>
        </>
      )}
      <Button type="link" onClick={() => setVisible(false)} className="!p-0">
        Hide winner contact info
        <EyeInvisibleFilled />
      </Button>
    </Space>
  ) : (
    <Button type="link" onClick={() => setVisible(true)}>
      See winnder contact info
      <EyeFilled />
    </Button>
  );
};
