import { useState } from "react";
import { DEFAULT_PAGESIZE } from "../components/URLSearchParamsProvider";

export const usePagination = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);
  return {
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};
