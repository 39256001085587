import { Avatar, Breadcrumb, Row, Space, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import { CreditGateway } from "../../api/CreditGateway";
import { UserGateway } from "../../api/UserGateway";
import { useReloadContext } from "../../components/Providers/ReloadProvider";
import { useURLSearchParams } from "../../components/URLSearchParamsProvider";
import { User } from "../../types";
import { formatMoney } from "../../utils/number";
import { usePromise } from "../../utils/usePromise";
import { AddCreditButton } from "./components/AddCredit";

type Props = {};

const columns = [
  {
    title: "Name",
    key: "name",
    render: (user: User) => {
      return (
        <Space size={20}>
          <Avatar src={user.photoURL} />
          <Space>
            <Typography.Paragraph className="text-h3 !mb-0">
              {user.username}
            </Typography.Paragraph>
          </Space>
        </Space>
      );
    },
  },
  {
    title: "",
    key: "action",
    fixed: "right" as const,
    render: ({ id }: { id: string }) => <AddCreditButton userId={id} />,
    width: 100,
  },
];

const CreditsTable = (props: { userId: string }) => {
  const { userId } = props;
  const { data, reload, loading } = usePromise(
    () => CreditGateway.getUserCredits(userId),
    [userId]
  );

  useReloadContext(reload);

  const columns = [
    {
      title: "Credit",
      key: "credit",
      dataIndex: "creditDisplay",
    },
    {
      title: "Redeemed",
      key: "redeemed",
      dataIndex: "redeemed",
      render: (redeemed?: boolean) => {
        return redeemed == null ? "" : redeemed ? "YES" : "NO";
      },
    },
  ];

  const dataSource = (data ?? []).map((item) => ({
    key: item.id,
    credit: item.deal.amount,
    creditDisplay: formatMoney(item.deal.amount),
    redeemed: item.redeemed as boolean | null,
  }));

  dataSource.push({
    key: "SUM",
    credit: 0,
    creditDisplay: `SUM: ${formatMoney(
      dataSource.reduce((pre, cur) => pre + cur.credit, 0)
    )}`,
    redeemed: null,
  });
  return (
    <Table
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      pagination={false}
      rowKey={"id"}
    />
  );
};

const expandedRowRender = (
  props: { id: string },
  _: any,
  __: any,
  expanded: boolean
) => {
  return expanded ? <CreditsTable userId={props.id} /> : null;
};

export const CreditsManager = (props: Props) => {
  const { commonUrlParams, onTableChange } = useURLSearchParams({
    pageSize: 10,
  });
  const { pageSize, page } = commonUrlParams;

  const { data, reload, loading } = usePromise(
    () => UserGateway.getUsers({ page, pageSize }),
    [pageSize, page]
  );
  useReloadContext(reload);

  const total = data?.total;

  return (
    <div className="page-container">
      <Row justify="space-between" align="middle">
        <Breadcrumb className="md:text-h2">
          <Breadcrumb.Item>
            <Link to="/" className="md:!h-9">
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Credits Manager</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Table
        dataSource={data?.data ?? []}
        columns={columns}
        loading={loading}
        pagination={{ total, current: page, pageSize }}
        onChange={onTableChange}
        expandable={{ expandedRowRender }}
        rowKey="id"
      />
    </div>
  );
};
