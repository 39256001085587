import { DownloadOutlined } from "@ant-design/icons";
import { Button, FloatButton } from "antd";
import _ from "lodash";
import { IMarketplaceProduct } from "../../../types";
import { categoryValues, csvFirstRow, tag2Values } from "./CSVUtils";
import { SizeOptions } from "./formItems/SizeInput";

export const DownloadCSVButton = (props: {
  dataSource: IMarketplaceProduct[];
  float?: boolean;
}) => {
  const dataSource = props.dataSource;

  const findYear = (name: string) => {
    // Matches: 90, 90s, 90's, 1990, 1990s, 1990's, as well as 20xx
    const yearRegex = /(\b(?:19)?[0-9][0-9]'?s?\b)|(\b(?:20)?[0-9][0-9]'?s?\b)/;
    const yearFromTitle = name.match(yearRegex);
    return yearFromTitle?.[0] ?? "";
  };

  const findSize = (name: string) => {
    // Matches from XS to XXXXL
    const sizeRegex =
      /(\bXXS\b)|(\bXS\b)|(\bS\b)|(\bM\b)|(\bL\b)|(\bXL\b)|(\bXXL\b)|(\bXXXL\b)|(\bXXXXL\b)/;
    const sizeFromTitle = name.match(sizeRegex);
    return sizeFromTitle?.[0] ?? "";
  };

  const convertSizeNumberToLabel = (size: string | undefined) => {
    return (
      SizeOptions.find((sizeOption) => {
        return sizeOption.value.toString() === size?.toString();
      })?.label ?? ""
    );
  };

  // Some tags have commas, so they need to be sanitized to not mess up the csv
  const getCleanTagName = (row: IMarketplaceProduct, index: number) => {
    return cleanString(row.tags?.[index]?.name ?? "").toString();
  };

  const cleanString = (text: string) => {
    return text.replaceAll(",", "").replaceAll("#", "").replaceAll("\n", " ");
  };

  const generateCSV = () => {
    let csvContent =
      "data:text/csv;charset=utf-8," + csvFirstRow.join(",") + "\n";

    _.map(dataSource, (row) => {
      let t0 = getCleanTagName(row, 0);
      let t1 = getCleanTagName(row, 1);
      let t2 = getCleanTagName(row, 2);

      let uncategorizedTags = [t0, t1, t2];

      let category1 = "";
      let tag1 = "";

      // Find the category tag, and remove from uncategorized
      if (categoryValues.includes(t0)) {
        category1 = t0;
        uncategorizedTags = [t1, t2];
      } else if (categoryValues.includes(t1)) {
        category1 = t1;
        uncategorizedTags = [t0, t2];
      } else if (categoryValues.includes(t2)) {
        category1 = t2;
        uncategorizedTags = [t0, t1];
      }

      category1 = category1 === "Misc." ? "" : category1; // Remove Misc.

      // Check if which of the uncategorized tags is in the tag 2 list
      if (
        uncategorizedTags.length === 2 &&
        tag2Values.includes(uncategorizedTags[0].toString())
      ) {
        tag1 = uncategorizedTags[1];
      } else {
        tag1 = uncategorizedTags[0];
      }

      let rowToAdd = [
        row.id,
        cleanString(row.name),
        findYear(row.name),
        tag1,
        category1,
        "T-Shirt", // This is the standard suffix for the final name
        "", // This will hold a formula to concatenate the final name
        row.description,
        row.price,
        convertSizeNumberToLabel(row.size),
        findSize(row.name),
        row.width,
        row.height,
        row.photoURLs[0],
        row.sourceURL,
      ];
      csvContent += rowToAdd.join(",") + "\n";
    });

    return csvContent;
  };

  const onClickDownloadCSV = (props?: { float?: boolean }) => {
    // This handles the creation & download of the csv
    const encodedUri = encodeURI(generateCSV());
    const link = document.createElement("a");
    const fileName = "inventory.csv";
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  return props.float ? (
    <FloatButton
      icon={<DownloadOutlined />}
      onClick={() => onClickDownloadCSV()}
    />
  ) : (
    <Button type="primary" onClick={() => onClickDownloadCSV()}>
      Download inventory CSV
    </Button>
  );
};
