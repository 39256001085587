import { Button, FormLabel, HStack, Input, Stack } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { DiscountGateway } from "../../api/DiscountGateway";

export const AddDiscountForm = ({
  setRefresh,
  refresh,
}: {
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [type, setType] = useState("");
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const [expiresAt, setExpiresAt] = useState<Date>(
    new Date(Date.now() + 15 * 60 * 1000)
  );
  // TODO we don't allow scheduling discounts in the future, but we can add that here later

  const handleSubmit = async () => {
    if (!amount) {
      alert("amount is required for discount creation");
      return;
    }

    if (type !== "percent" && type !== "flat") {
      alert("type must be percent or flat");
      return;
    }

    if (_.isNil(categoryId)) {
      alert("must include categoryId");
      return;
    }

    const currentTime = Date.now();
    const discount = await DiscountGateway.createDiscount({
      type,
      amount,
      resourceId: categoryId,
      startsAt: new Date(currentTime),
      expiresAt, // 15 minute duration flash sale
      resourceType: "TAG",
    });
    if (!discount.success) {
      alert(discount.message);
    }
    setRefresh((refresh) => !refresh);
  };

  return (
    <Stack
      flex={1}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={1}
      pt={2}
    >
      <Input
        placeholder={`type "percent" or "flat"`}
        value={type}
        onChange={(e) => {
          setType(e.target.value);
        }}
        size="sm"
      />
      <Input
        placeholder={"Amount"}
        type={"number"}
        value={amount}
        onChange={(e) => {
          setAmount(e.target.value ? _.toNumber(e.target.value) : undefined);
        }}
        size="sm"
      />
      <Input
        placeholder={"Tag ID"}
        value={categoryId}
        onChange={(e) => {
          setCategoryId(e.target.value);
        }}
        size="sm"
      />
      {/* <Text>NOTE: this will send a push notification to users.</Text> */}
      <HStack>
        <FormLabel>Expire Date: </FormLabel>
        <DateTimePicker value={expiresAt} onChange={setExpiresAt} />
      </HStack>
      <Stack
        width={"100%"}
        mt={"2rem"}
        direction={"row"}
        padding={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          flex={1}
          fontSize={"sm"}
          //rounded={"full"}
          bg={"blue.400"}
          color={"white"}
          boxShadow={
            "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
          }
          _hover={{ bg: "blue.500" }}
          _focus={{ bg: "blue.500" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};
